import React, {useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../componentes/Footer';
import SubMenusFinancas from './submenusFinancas';


import TabLancamentosContasBancarias from './tabLancamentosContasBancarias';
import TabContasBancarias from './tabContasBancarias';
import TabplanoContas from './tabPlanoContas';

export default function ContasBancarias() {

  const [tabPage, settabPage] = useState('1');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Contas bancárias</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>

          <SubMenusFinancas activePage="contasBancarias" />

          <div className='container'>
            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                  <Tab label="Lançamentos contas Bancárias" value="1" />
                  <Tab label="Contas Bancárias" value="2" />
                  <Tab label="Plano de contas" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TabLancamentosContasBancarias />
              </TabPanel>

              <TabPanel value="2">
                <TabContasBancarias />
              </TabPanel>

              <TabPanel value="3">
                <TabplanoContas />
              </TabPanel>
            </TabContext>
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}