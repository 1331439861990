import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import moment from 'moment';
import { Avatar, CircularProgress, Drawer, IconButton, Snackbar, Typography } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';


import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import api from '../../services/api';
import Swal from 'sweetalert2';
import Fancybox from '../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ButtonSquadPrimaryForInput from '../ButtonSquadPrimaryForInput';

import { listenerMessages } from "../../services/socket-listener";
import ImgComponent from './ImgComponent';
import { FixedSizeList as List } from 'react-window';
import StickerComponent from './StickerComponent';

export default function MensagesBar() {

    let IconSend = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 35.041 35.921">
            <g id="send-message" transform="translate(-4.961 0)">
                <path id="Caminho_350" data-name="Caminho 350" d="M5.012,34.282c-.3,1.356.746,1.931,1.713,1.5L39.382,19.033h0a1.242,1.242,0,0,0,0-2.145h0L6.724.142c-.966-.433-2.008.141-1.713,1.5.02.092,1.951,8.692,3,13.369L25.06,17.961,8.012,20.912c-1.05,4.677-2.981,13.277-3,13.369Z" transform="translate(0 0)" fill="#fff"/>
            </g>
        </svg>
    
    )
    
    let IconAnexo = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 38.44 40.899">
        <g id="paper-clip" transform="translate(-14.394 1.002)">
            <g id="Grupo_391" data-name="Grupo 391" transform="translate(14.394 -1.002)">
            <path id="Caminho_351" data-name="Caminho 351" d="M52.331,13.893a1.7,1.7,0,0,0-2.41.006L29.435,34.5A6.817,6.817,0,0,1,19.8,24.853L40.891,3.65a4.26,4.26,0,0,1,6.021,6.029L29.439,27.152l0,0a1.7,1.7,0,0,1-2.406-2.413l8.434-8.436a1.7,1.7,0,1,0-2.41-2.41l-8.434,8.436a5.112,5.112,0,0,0,7.23,7.229l.006-.007L49.321,12.089A7.668,7.668,0,1,0,38.477,1.243l-21.092,21.2A10.225,10.225,0,0,0,31.849,36.9L52.338,16.3A1.7,1.7,0,0,0,52.331,13.893Z" transform="translate(-14.394 1.002)" fill="#fff"/>
            </g>
        </g>
    </svg>
    )

    const refScroollMsgBox = useRef(null);

    const [loaderPage, setloaderPage] = useState(false);
    const [loaderMsg, setloaderMsg] = useState(false);
    const [whatsAppInfo, setwhatsAppInfo] = useState([]);
    const [chats, setchats] = useState([]);
    const [mensagesChat, setmensagesChat] = useState([]);
    const [openChatsMenu, setopenChatsMenu] = useState(false);
    const [activeChat, setactiveChat] = useState();
    const [notifications, setNotifications] = useState([]);

    const activeChatRef = useRef(activeChat);

    const getDatas = async()=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
  
        let responseinfo = await api.post('whatsAppInfo', {}, 
            {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            }
        );

        let responseprofilepic = await api.post('whatsAppProfilePic', {
            "phone": responseinfo.data.response.phoneNumber.replace("@c.us", ""),
            }, 
            {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            }
        );
            
        setwhatsAppInfo({
            info: responseinfo.data.response,
            photo: responseprofilepic.data.response,
            session: responseinfo.data.session
        }); 
    }

    // const moveChatToTop = (serializedId) => {
    //     const chatIndex = chats.findIndex(
    //         (chat) => chat.id._serialized === serializedId
    //     );

    //     if (chatIndex === -1) return;
    
    //     const chatToMove = chats[chatIndex];
    //     const updatedChats = [...chats];
    //     updatedChats.splice(chatIndex, 1);
    
    //     updatedChats.unshift(chatToMove);
    //     setchats(updatedChats);
    // };

    const moveChatToTop = async(serializedId) => {
        const chatIndex = chats.findIndex(
            (chat) => chat.id._serialized === serializedId
        );
    
        if (chatIndex !== -1) {
            const chatToMove = chats[chatIndex];
            const updatedChats = [...chats];
            updatedChats.splice(chatIndex, 1);
            updatedChats.unshift(chatToMove); 
            setchats(updatedChats);
        } else {
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);

            let response = await api.post('whatsAppGetChatByid', {
                "phone": serializedId.replace("@c.us", ""),
                }, 
                {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${auth.token}`
                    }
                }
            );

            const newChat = response.data;
            const updatedChats = [newChat, ...chats];
            setchats(updatedChats);
        }
    };

    const getAllChats = async () => {
        try {
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);
    
            // Faz a requisição para obter todos os chats
            let response = await api.post('whatsAppAllChats', {}, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            });
    
            let chats = response.data.response;
    
            // Filtra apenas os chats que não são de grupos diretamente
            let filteredChats = chats.filter(chat => !chat.isGroup);
    
            // Faz a requisição para obter as mensagens não lidas
            let unreadMessagesResponse = await api.post('whatsAppUnreadMessages', {}, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            });
    
            let unreadMessages = unreadMessagesResponse.data.response;
    
            // Cria um Map para associar rapidamente as mensagens não lidas a seus respectivos chats
            const unreadMessagesMap = new Map();
    
            unreadMessages.forEach(messageGroup => {
                // Associa cada usuário (user) com suas mensagens não lidas
                if (!unreadMessagesMap.has(messageGroup.id.user)) {
                    unreadMessagesMap.set(messageGroup.id.user, []);
                }
                unreadMessagesMap.get(messageGroup.id.user).push(...messageGroup.messages);
            });
    
            // Cria um array de chats com as mensagens não lidas já associadas
            const enrichedChats = filteredChats.map(chat => ({
                ...chat,
                unreadMessages: unreadMessagesMap.get(chat.id.user) || [] // Associa as mensagens ou um array vazio
            }));
    
            // Define no estado o array otimizado de chats
            setchats(enrichedChats);
    
        } catch (error) {
            console.error('Erro ao buscar chats ou mensagens não lidas:', error);
        }
    };
    
    listenerMessages((err, data) => {
        if (err) return;
        if (data.response.session !== whatsAppInfo?.session) return;
        if(!["image", "video", "file", "ptt", "sticker", "chat", "sticker"].includes(data.response.type)) return;
       
        if (activeChatRef.current?.chatId === data?.response?.chatId || activeChatRef.current?.contact?.id?._serialized === data?.response?.chatId) {
          setmensagesChat((prevMensagesChat) => {
            const filteredList = prevMensagesChat.filter(
              (filtro) => filtro.id !== data.response.id
            );
            const newList = [...filteredList, data.response];
            return newList;
          });
          scrollBottonMsgBox(); 
        }else{
            !data.response.fromMe && setNotifications((prev) => {
                const newNotifications = [...prev, data.response];
                if (newNotifications.length > 3) {
                  newNotifications.shift();
                }
                return newNotifications;
            });
        }
        
        moveChatToTop(data?.response?.chatId);
    
    });

    const getMessages = async (phone, count, showLoader = true) => {

        if(showLoader){
            setloaderMsg(true);
            setmensagesChat([]);
        }

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('whatsAppGetMessages', {
            "phone": phone, 
            "count": count
        }, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });
        setmensagesChat(response.data.response);
        scrollBottonMsgBox(); 
        setloaderMsg(false);
    };

    const Input = styled('input')({
        display: 'none',
    });

    useEffect(async ()=>{ 
        await getDatas();
        await getAllChats();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(() => {
        activeChatRef.current = activeChat;
    }, [activeChat]);

    useEffect(() => {
        !openChatsMenu && setactiveChat('');
    }, [openChatsMenu]);

    const openChat = (chat) => {
        setactiveChat(chat);
        getMessages(chat.contact.id.user, 33, true);
        setopenChatsMenu(true)
    };

    const openChatWidthNotification = (chat) => {
        chat.contact = chat.contact || {};
        chat.contact.id = chat.contact.id || {};
        chat.current = chat.current || {};
        chat.current.contact = chat.current.contact || {};
        chat.current.contact.id = chat.current.contact.id || {};

        chat.current.contact.id._serialized = chat.sender.id;
        chat.contact.id.user = chat.sender.id.replace("@c.us", "");

        setactiveChat(chat);
        getMessages(chat.sender.id.replace("@c.us", ""), 33, true);
        setopenChatsMenu(true)
    };

    const scrollBottonMsgBox = () => {
        // refScroollMsgBox.current?.scrollIntoView({behavior: 'smooth'});
        let heightSet = refScroollMsgBox.current?.scrollHeight;
        refScroollMsgBox.current?.scrollTo(0, heightSet);
    };

    const handleUpload = async(e)=>{
    
        setloaderPage(true);
    
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let anexos = e.target.files;
    
        let formData = new FormData();
        // formData.append('usuario_pessoa_id', dadosUsuarioLogado.id);
        formData.append('mensagem', formik.values.mensagem);
    
        if(anexos.length > 5){
          Swal.fire(
            'Ops, Limite por vez atingido!',
            'Você só pode enviar 5 arquivos por vez.',
            'warning'
          )
        }else{
        
          for await (let anexo of anexos) {
            if(anexo.type === 'image/png' || anexo.type === 'image/gif' || anexo.type === 'image/jpeg' || anexo.type === 'application/pdf'){
              formData.append('anexos', anexo);
            }else{
              Swal.fire(
                'Ops, Arquivo inválido!',
                'Apenas os formatos .png, .jpg, .jpeg e .PDF são permitidos!',
                'error'
              )
            }
          }
    
          await api.put("uploadAnexosStatusMensagem", formData, {
            headers: { 
              "Content-type": "multipart/form-data",
              "Authorization": `Bearer ${auth.token}`
            },
            onUploadProgress: e => {
              const progress = parseInt(Math.round((e.loaded * 100) / e.total));
              // setprogressUpload(progress)
            },
          });
    
          
          formik.setFieldValue('mensagem', '');
          scrollBottonMsgBox();
          
        }
        
        setloaderPage(false);
    
    }

    function formatNumber(num) {
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace('.0', '') + 'k';
        }
        return num;
    }

    const validationSchema = yup.object({
        mensagem: yup
          .string('Informe a mensagem'),
    });

    const formik = useFormik({
        initialValues: {
          mensagemchat: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
    
          //GET DADOS USER
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
    
          if(values.mensagemchat){
            formik.setFieldValue('mensagemchat', '');

            await api.post(`whatsAppSendMessages`, {
                "phone": activeChat.contact?.id?.user,
                "message": values.mensagemchat,
                "isGroup": activeChat.isGroup
            },
            {
              headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
              }
            });

          }
        }
    });

    const ChatItem = ({ index, style }) => {
        const v = chats[index];
        return (
          <div key={index} style={style}>
              <aside className={activeChat?.contact?.id?.user === v.contact.id.user ? 'active' : ''} onClick={() => openChat(v)}>
                <Badge color="primary" overlap="circular" invisible={v?.unreadMessages?.length === 0} badgeContent=" " variant="dot">
                  <Avatar
                    alt={v.contact.formattedName}
                    src={v.contact?.profilePicThumbObj?.eurl}
                    sx={{ width: 46, height: 46 }}
                  />
                </Badge>
                <span>
                  <h3>{v.contact.formattedName}</h3>
                  <p>{v.contact.id.user}</p>
                </span>
              </aside>
          </div>
        );
    };

    const handleCloseAlertNewMsg = (id) => {
        setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    };
    
    return (
        <>
            {notifications.map((notification) => (
                <Snackbar
                    key={notification.id}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => handleCloseAlertNewMsg(notification.id)}
                    message={
                        <Box display="flex" alignItems="center" onClick={() => openChatWidthNotification(notification)}>
                        <Avatar alt={notification.sender?.formattedName} src={notification.sender?.profilePicThumbObj?.eurl} />
                        <Box ml={2}>
                            <Typography variant="subtitle1" fontWeight="bold">
                            {notification.sender?.formattedName}
                            </Typography>
                            <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '269px',
                            }}
                            >
                            {notification.type === "chat" ? notification.body : 'Nova mensagem...'}
                            </Typography>
                        </Box>
                        </Box>
                    }
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleCloseAlertNewMsg(notification.id)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            ))}

            <div className='container-bar-mensage'>
                <Avatar
                    onClick={()=>setopenChatsMenu(true)}
                    alt={whatsAppInfo?.info?.pushname}
                    src={whatsAppInfo?.photo?.eurl}
                    sx={{ width: 53, height: 53, cursor: 'pointer' }}
                />

                <article>
                    {
                        chats?.length === 0 &&
                        <Stack spacing={1} sx={{ alignItems: 'center' }}>
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                            <Skeleton variant="circular" width={46} height={46} sx={{ bgcolor: 'grey.600' }} />
                        </Stack>
                    }
            
                    {chats?.slice(0, 10).map((v, k) => {
                        return (
                            <div key={k} >
                                <Badge color="primary" overlap="circular" invisible={v?.unreadMessages?.length === 0} badgeContent=" " variant="dot">
                                    <Avatar
                                        onClick={()=> openChat(v)}
                                        alt={v.contact?.formattedName}
                                        src={v.contact?.profilePicThumbObj?.eurl}
                                        sx={{ width: 46, height: 46, cursor: 'pointer' }}
                                    />
                                </Badge>
                            </div>
                        )   
                    })}
                    
                    {
                        chats.length > 9 &&
                        <Avatar onClick={()=>setopenChatsMenu(true)} sx={{ width: 46, height: 46, cursor: 'pointer' }}>
                            +{formatNumber(chats.length - 9)}
                        </Avatar>
                    }
                    
                </article>

                <Drawer
                    anchor='right'
                    open={openChatsMenu}
                    onClose={() => setopenChatsMenu(false)}
                >   

                    <div className='chatDrawer'>
                        <div className='chat-conversas'>
                            <div className='header-conversas'>
                                <h3>Conversas</h3>

                                {/* <h2 onClick={()=> moveChatToTop('5511950725748@c.us')}>1</h2>
                                <h2 onClick={()=> moveChatToTop('5511973105657@c.us')}>2</h2> */}
                            </div>

                            <article>
                                <List
                                    height={window.innerHeight}
                                    itemCount={chats.length}
                                    itemSize={80}
                                    width="100%"
                                    >
                                    {ChatItem}
                                </List>
                            </article>
                        </div>

                        <Fancybox>
                            <div className='chat-detail-conversa'>

                                {
                                    loaderMsg &&
                                
                                    <div className='loader-msgs'>
                                        <CircularProgress />
                                    </div>
                                }    

                                <article ref={refScroollMsgBox}>

                                {mensagesChat?.map((v, k) =>{
                                    let now = moment(new Date());
                                    let end = moment(v.timestamp * 1000);
                                    
                                    let seconds = now.diff(end, 'seconds');
                                    let minutes = now.diff(end, 'minutes');
                                    let hours = now.diff(end, 'hours');
                                    let days = now.diff(end, 'days');
                                    let weeks = now.diff(end, 'weeks');
                                    
                                    let tempo = '';
                                    
                                    if (seconds < 30) {
                                        tempo = `há ${seconds}s`;
                                    } else if (minutes < 58) {
                                        tempo = `há ${minutes}min`;
                                    } else if (hours <= 22) {
                                        tempo = hours === 1 ? `há ${hours} hora` : `há ${hours} horas`;
                                    } else if (days <= 7) {
                                        tempo = days === 1 ? `há ${days} dia` : `há ${days} dias`;
                                    } else {
                                        tempo = `${moment(v.timestamp * 1000).format('DD/MM/YYYY')} às ${moment(v.timestamp * 1000).format('HH:mm')}`;
                                    }

                                    return (
                                    <div key={k}>

                                        {
                                            v.type === 'image' ? 
                                                <div style={{ paddingInline: 19, paddingBlock: 10 }}>
                                                    <ImgComponent data={v} tempo={tempo} />
                                                </div>
                                            : v.type === "chat" ?
                                                <div className={ v.fromMe ? `container-box-msg right` : `container-box-msg left`}>
                                                    <div className='box-msg'>
                                                    <aside>
                                
                                                        <div style={{ marginTop: '5px', marginBottom: '2px'}}>
                                                            <p style={{ whiteSpace: 'pre-wrap' }}>{v.content}</p>
                                                        </div>

                                                        <span>
                                                            <Tooltip title={`${moment(v.data).format('DD/MM/YYYY')} às ${moment(v.data).format('HH:mm')}`}>
                                                                <Button>{tempo}</Button>
                                                            </Tooltip>
                                                        </span>

                                                    </aside>
                                                    </div>
                                                </div>
                                            : v.type === "sticker" ?
                                                <StickerComponent data={v} tempo={tempo} />
                                            : <></>

                                        }
                                    </div>
                                    )
                                })}

                                </article>

                                <form method='post' className='form-mensage' onSubmit={formik.handleSubmit}>
                                    <div style={{ display: 'flex', marginTop: '10px'}}>  
                                                
                                        {/* <label htmlFor="button-file-upload-msg">
                                            <Input accept="image/png, image/gif, image/jpeg, .pdf" id="button-file-upload-msg" multiple onChange={handleUpload} type="file" />
                                            <ButtonSquadPrimaryForInput type="button" component="span" icon={<IconAnexo/>}/>
                                        </label> */}

                                        <TextField
                                            fullWidth
                                            id="mensagemchat"
                                            name="mensagemchat"
                                            placeholder="Mensagem..."
                                            margin="none"
                                            type="text"
                                            value={formik.values.mensagemchat}
                                            onChange={formik.handleChange}
                                            error={formik.touched.mensagemchat && Boolean(formik.errors.mensagemchat)}
                                            helperText={formik.touched.mensagemchat && formik.errors.mensagemchat}
                                            autoComplete="off"
                                            style={{ marginLeft: '5px' }}
                                            InputProps={{
                                                style: {
                                                    color: 'white', // Cor do texto
                                                },
                                                sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#acacac', // Cor da borda
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#c9c6c6', // Cor da borda ao passar o mouse
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#c9c6c6', // Cor da borda quando focado
                                                },
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#acacac' }, // Cor do label (se houver)
                                            }}
                                            sx={{
                                                input: {
                                                    color: 'white', // Cor do texto digitado
                                                },
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: '#acacac', // Cor do placeholder
                                                },
                                            }}
                                        />
                                        <ButtonSquadPrimaryForInput icon={<IconSend/>} onClick={()=>{ formik.handleSubmit() }}/>

                                    </div>
                                </form>
                            </div>
                        </Fancybox>
                    </div>
                </Drawer>
            </div>
        </>
    );
}