import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory, Link } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// import Swal from 'sweetalert2';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import SliderComparacaoPrecos from '../../../componentes/SliderComparacaoPrecos';

import api from '../../../services/api';
import { AnoMask, mvalor, placaMask, mplaca } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';


export default function NovoVeiculoPlaca() {

  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
      <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconCarro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 47.701 17.828">
      <g id="sedan" transform="translate(0 -160.323)">
        <path id="Caminho_46" data-name="Caminho 46" d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z" transform="translate(-82.091 -118.438)" fill="#4a4a4a"/>
        <path id="Caminho_47" data-name="Caminho 47" d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z" transform="translate(-365.595 -118.437)" fill="#4a4a4a"/>
        <path id="Caminho_48" data-name="Caminho 48" d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z" fill="#4a4a4a"/>
        <path id="Caminho_49" data-name="Caminho 49" d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z" transform="translate(-152.655 -109.039)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconContrato = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.107 27.107">
      <g id="contract" transform="translate(0 -0.5)">
        <path id="Caminho_50" data-name="Caminho 50" d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_51" data-name="Caminho 51" d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0" transform="translate(-203.11 -105.627)" fill="#4a4a4a"/>
        <path id="Caminho_52" data-name="Caminho 52" d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0" transform="translate(-56.986 -279.609)" fill="#4a4a4a"/>
        <path id="Caminho_53" data-name="Caminho 53" d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -73.785)" fill="#4a4a4a"/>
        <path id="Caminho_54" data-name="Caminho 54" d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -141.696)" fill="#4a4a4a"/>
        <path id="Caminho_55" data-name="Caminho 55" d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -209.591)" fill="#4a4a4a"/>
        <path id="Caminho_56" data-name="Caminho 56" d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0" transform="translate(-233.817 -283.767)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconModeloMensagem = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
      <g id="mensagem" transform="translate(0 0)">
        <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
        <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
        <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconWhatsApp = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 24 24">
      <g id="whatsapp" transform="translate(0 0)">
        <path id="Caminho_414" data-name="Caminho 414" d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z" fill="#4a4a4a"/>
        <path id="Caminho_415" data-name="Caminho 415" d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);
  const [loaderPage, setloaderPage] = useState(false);
  const [modelos, setmodelos] = useState([]);
  const [mesReferencia, setmesReferencia] = useState([]);
  const [consultaFipe, setconsultaFipe] = useState([]);

  const [combustiveis, setcombustiveis] = useState([]);
  const [cambios, setcambios] = useState([]);
  const [cores, setcores] = useState([]);

  const [dadosPlaca, setdadosPlaca] = useState([]);
  const [jsonConsultaFIpe, setjsonConsultaFIpe] = useState();
  
  const [placaNaoDisponivel, setplacaNaoDisponivel] = useState(false);

  const validationSchema = yup.object({
    placa: yup
      .string('Informe a placa do veículo')
      .test({
          message: () => "Placa Obrigatória",
          test: async (values) => {
            if(step === 0 && !values){
              return false
            }else{
              return true
            } 
          },
        })
       .test({
            message: () => "Placa inválida, informe uma plca válida",
            test: async (values) => {
              let placa = values?.toUpperCase();
              placa = placa?.replace(/\s/, ""); //Removendo espaços
              placa = placa?.replace(/[^\w\-]+/g, ""); //Removendo caracters especiais e acentos
  
              let placaNormal = placa?.match("[A-Z]{3}-[0-9]{4}");
              let placaMercosul = placa?.match("[A-Z]{3}[0-9][A-Z][0-9]{2}");
  
              placa = placaNormal && placaNormal != 'null' ? placaNormal : placaMercosul;
  
              if(step === 0 && !placa){
                return false
              }else{
                return true
              } 
  
            },
          })
      .test({
        message: (data) => `Já existe um veículo cadastrado com a placa ${data.value}.`,
        test: async (values) => {
          if (step === 0) { 
            try {

              let auth = localStorage.getItem("auth");
              auth = JSON.parse(auth);

              let response = await api.post(`verificaPlacaExistenteVeiculo`, {
                  "placa": values,
              },
              {
                headers: {
                  "Content-type": "application/json",
                  "Authorization": `Bearer ${auth.token}`
                }
              });

              if (response.data.veiculoExiste) {
                return false;
              }else{
                return true;
              }

            }catch (error) {
              // console.log(error);
            }
          }else{
              return true
          } 
        },
      }),
    modelo: yup
      .string('Selecione o modelo do veículo')
      .required('Modelo Obrigatório')
      .test({
        message: () => "Selecione o modelo do veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    renavam: yup
      .string('Informe o renavam do veículo')
      .test({
        message: () => "Renavam Obrigatório",
        test: async (values) => {
          if(step === 0 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    chassi: yup
      .string('Informe o chassi do veículo')
      .test({
        message: () => "Chassi Obrigatório",
        test: async (values) => {
          if(step === 0 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    combustivel: yup
      .string('Informe o Combustível do veículo')
      .test({
        message: () => "Combustível Obrigatório",
        test: async (values) => {
          if(step === 0 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    portas: yup
      .string('Obrigatório')
      .test({
        message: () => "Obrigatório",
        test: async (values) => {
          if(step === 0 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    cambio: yup
      .string('Informe o tipo Câmbio do veículo')
      .test({
        message: () => "Câmbio Obrigatório",
        test: async (values) => {
          if(step === 0 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    cor: yup
    .string('Informe a cor do veículo')
    .test({
      message: () => "Cor Obrigatória",
      test: async (values) => {
        if(step === 0 && !values){
          return false
        }else{
          return true
        } 
      },
    }),
    precovenda: yup
      .string('Informe o preço de venda do veículo')
      .test({
        message: () => "Preço de venda Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),

  });

  const formik = useFormik({
    initialValues: {
      modelo: '',
      placa: '',
      renavam: '',
      chassi: '',
      combustivel: '',
      portas: '',
      cambio: '',
      cor: '',
      precovenda: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      step < 1 && setstep(step+1)
      
      if(step === 1 && formik.values.precovenda){ //TUDO CERTO PRA CADASTRAR
        
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addVeiculo`, {
          "titulo": dadosPlaca?.marca+' '+dadosPlaca?.modelo,
          "tipo": (dadosPlaca?.segmento == 'Moto' ? 2 : 1),
          "marca": dadosPlaca?.marca,
          "modelo": dadosPlaca?.modelo,
          "ano":  dadosPlaca?.anoModelo,
          "anoFabricacao": dadosPlaca?.ano,
          "cor": values.cor,
          "combustivel": values.combustivel,
          "portas": values.portas,
          "cambio": values.cambio,
          "precoVenda": formataDinheiroforBanco(values.precovenda),
          "precoFipe": formataDinheiroforBanco(consultaFipe.Valor),
          "fipeCodigo": consultaFipe.CodigoFipe,
          "fipemesReferencia": consultaFipe.MesReferencia,
          "placa": values.placa,
          "renavam": values.renavam,
          "chassi": values.chassi,
          "jsonConsultaFIpe": jsonConsultaFIpe
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        history.push(`/cadastro/veiculo/${response.data.codigo}`);


      }      
    }
  });


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    // if(erros === 0){
    //   step === 0 && seterrorStep1(false);
    // }else{
    //   step === 0 && seterrorStep1(true);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);


  let itensSubMenu = [
    { active: "", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
    { active: "active", title: "Veículos", rota: "/cadastros/veiculos", icon: <IconCarro/> },
    { active: "", title: "Contratos", rota: "/cadastros/contratos", icon: <IconContrato/> },
    { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
    { active: "", title: "Listas de Transmissões", rota: "/cadastros/listasTransmissoes", icon: <IconWhatsApp/> },
  ];

  const getConsultarTabelaDeReferencia = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultarTabelaDeReferencia`, {},
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setmesReferencia(response.data[0])
  }

  const getCoresCombustiveisCambios = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let cambios = await api.get(`listagemCambios`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let combustiveis = await api.get(`listagemCombustiveis`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let cores = await api.get(`listagemCores`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setcambios(cambios.data);
    setcombustiveis(combustiveis.data);
    setcores(cores.data);

  }

  useEffect(()=>{
    getConsultarTabelaDeReferencia();
    getCoresCombustiveisCambios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

 const handlePlaca = async()=>{
    let placa = formik.values.placa;

    if( placa.length >= 7 ){

      let placaNormal = placa?.match("[A-Z]{3}-[0-9]{4}");
      let placaMercosul = placa?.match("[A-Z]{3}[0-9][A-Z][0-9]{2}");

      let validarplaca = placaNormal && placaNormal != 'null' ? placaNormal : placaMercosul;

      if(validarplaca){

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        try {

          setloaderPage(true);
          setplacaNaoDisponivel(false);

          let modeloplaca = await api.post(`consultarModeloPorPlaca`, {
              "placa": placa
          },
          {
              headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
              }
          });

          modeloplaca = modeloplaca.data;

          let modelosArray = [];
          let indiceMod = 0

          for await (let data of modeloplaca?.valoresFipe) {

              indiceMod ++;

              let dados = {
                  label: data.modelo,
                  id: indiceMod,
                  codigoFipe: data.codigoFipe,
              };

              modelosArray.push(dados);
          }

          formik.setFieldValue('chassi', modeloplaca?.chassi);
          formik.setFieldValue('modelo', 'null');

          setmodelos(modelosArray);
          setdadosPlaca(modeloplaca);
          setloaderPage(false);
          
        }catch(error) {
          setdadosPlaca([]);
          setplacaNaoDisponivel(true);
          setloaderPage(false);
        }

      }
    }
 }
  
  const handleModeloGetTodosParametros = async(e, value)=>{
    
    if(value === null) return false

    setloaderPage(true);
    formik.setFieldValue('modelo', JSON.stringify(value));

    let codigoFipe = value.codigoFipe;

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultarCodigoFipe`, {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": (dadosPlaca?.segmento == 'Moto' ? 2 : 1),
        "anoModelo": dadosPlaca?.anoModelo.toString(),
        "codigoFipe": codigoFipe
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setjsonConsultaFIpe(
      {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": (dadosPlaca?.segmento == 'Moto' ? 2 : 1),
        "codigoMarca": null,
        "codigoModelo": null,
        "ano": dadosPlaca?.ano,
        "codigoTipoCombustivel": response.SiglaCombustivel,
        "anoModelo": dadosPlaca?.anoModelo,
        "tipoConsulta": "tradicional"
      }
    );

    setconsultaFipe(response.data);
    setloaderPage(false);

  }

  

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Novo veículo por placa</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        <div className='container-page'>
          <Submenu itens={ itensSubMenu } />
          
          <div className='box-steps' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Stepper activeStep={step} alternativeLabel style={{ width: '600px'}}>
                <Step>
                  <StepLabel error={errorStep1}>Veículo</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Preço</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-pessoas'>

                <form method='post' onSubmit={formik.handleSubmit}>
                  <h2>Cadastro de veículo</h2>
                  
                {
                    dadosPlaca?.valoresFipe &&

                    <div style={{ textAlign: 'center' }}>
                        <div className='box-placa'>
                            {
                                dadosPlaca?.placa?.match("[A-Z]{3}-[0-9]{4}") ?
                                <div className='placaNormal'><span>{dadosPlaca?.uf}-{dadosPlaca?.municipio}</span><p>{dadosPlaca?.placa}</p></div>
                                :
                                <div className='placaMercosul'><p>{dadosPlaca?.placa}</p></div>
                            }                        
                        </div>

                        <p style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '14px', color: '#666' }}>
                            {dadosPlaca?.marca} - {dadosPlaca?.modelo} - {dadosPlaca?.ano} / {dadosPlaca?.anoModelo} <br/>
                            {dadosPlaca?.combustivel} - {dadosPlaca?.cilindrada} {dadosPlaca?.potencia} <br/>
                            {dadosPlaca?.municipio}-{dadosPlaca?.uf} <br/>
                        </p>
                    </div>

                } 


                {
                    placaNaoDisponivel &&

                    <div style={{width: '100%', marginBottom: '20px' }}>
                      <Alert severity="warning">
                        <AlertTitle>Ops! Placa não disponível</AlertTitle>
                        No momento a placa <strong>{formik.values.placa}</strong>, não está disponivel, você pode fazer o <Link to={`/cadastros/veiculos/novo`}>Cadastro pelo modelo.</Link>
                      </Alert>
                    </div>
                }
                
                  
                  {
                    step === 0 &&

                    <Grid container spacing={2} >

                      <Grid item xs={12} md={12}>
                        <TextField
                          className='input'
                          fullWidth
                          id="placa"
                          name="placa"
                          label="Placa"
                          type="text"
                          value={formik.values.placa}
                          onChange={formik.handleChange}
                          onBlur={()=>{ handlePlaca() }}
                          error={formik.touched.placa && Boolean(formik.errors.placa)}
                          helperText={formik.touched.placa && formik.errors.placa}
                          
                          onKeyUp={()=>{ formik.setFieldValue('placa', mplaca(formik.values.placa)) }}
                          inputProps={{
                            // inputComponent: placaMask,
                            maxLength: 8,
                          }}
                          />
                      </Grid>

                      {
                        dadosPlaca?.valoresFipe &&

                        <>
                          <Grid item xs={12} md={12}>
                            <FormControl  fullWidth={true} >
                                <Autocomplete
                                    className='input'
                                    disablePortal
                                    required
                                    id="autocomplete-modelo"
                                    options={modelos}
                                    isOptionEqualToValue={(option, value) => option.codigoFipe === value.codigoFipe}
                                    sx={{ width: '100%' }}
                                    value={formik.values.modelo ? JSON.parse(formik.values.modelo) : null }
                                    onChange={handleModeloGetTodosParametros}
                                    noOptionsText="Nenhum modelo encontrado"
                                    renderInput={(params) => 
                                    <TextField {...params}
                                        label="Modelo"
                                        error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                                    />}
                                />
                                <FormHelperText error>{formik.touched.modelo && formik.errors.modelo}</FormHelperText>
                            </FormControl>
                            </Grid>

                          <Grid item xs={12} md={6}>
                            <TextField
                              className='input'
                              fullWidth
                              id="renavam"
                              name="renavam"
                              label="Renavam"
                              type="text"
                              value={formik.values.renavam}
                              onChange={formik.handleChange}
                              error={formik.touched.renavam && Boolean(formik.errors.renavam)}
                              helperText={formik.touched.renavam && formik.errors.renavam}
                              inputProps={{
                                maxLength: 45,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <TextField
                                className='input'
                                fullWidth
                                id="chassi"
                                name="chassi"
                                label="Chassi"
                                type="text"
                                value={formik.values.chassi}
                                onChange={formik.handleChange}
                                error={formik.touched.chassi && Boolean(formik.errors.chassi)}
                                helperText={formik.touched.chassi && formik.errors.chassi}
                                inputProps={{
                                maxLength: 45,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <FormControl  fullWidth={true} >
                                <InputLabel id="label-combustivel">Combustível</InputLabel>
                                <Select
                                    className='input'
                                    labelId="label-combustivel"
                                    name="combustivel"
                                    label="Combustível"
                                    placeholder='Combustível'
                                    value={formik.values.combustivel}
                                    onChange={formik.handleChange}
                                    error={formik.touched.combustivel && Boolean(formik.errors.combustivel)}
                                >
                                    {combustiveis.map((v, k) =>{
                                        return (
                                        <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                        )
                                    })}
                                    
                                    
                                </Select>
                                <FormHelperText error>{formik.touched.combustivel && formik.errors.combustivel}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                <InputLabel id="label-portas">Portas</InputLabel>
                                <Select
                                    className='input'
                                    labelId="label-portas"
                                    id="portas-select"
                                    name="portas"
                                    label="Portas"
                                    placeholder='Portas'
                                    value={formik.values.portas}
                                    onChange={formik.handleChange}
                                    error={formik.touched.portas && Boolean(formik.errors.portas)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                                <FormHelperText error>{formik.touched.portas && formik.errors.portas}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl  fullWidth={true} >
                                <InputLabel id="label-cambio">Câmbio</InputLabel>
                                <Select
                                    className='input'
                                    labelId="label-cambio"
                                    name="cambio"
                                    label="Câmbio"
                                    placeholder='Câmbio'
                                    value={formik.values.cambio}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cambio && Boolean(formik.errors.cambio)}
                                >
                                    {cambios.map((v, k) =>{
                                        return (
                                        <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText error>{formik.touched.cambio && formik.errors.cambio}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl  fullWidth={true} >
                                <InputLabel id="label-cor">Cor</InputLabel>
                                <Select
                                  className='input'
                                  labelId="label-cor"
                                  name="cor"
                                  label="Cor"
                                  placeholder='Cor'
                                  value={formik.values.cor}
                                  onChange={formik.handleChange}
                                  error={formik.touched.cor && Boolean(formik.errors.cor)}
                                >
                                  {cores.map((v, k) =>{
                                      return (
                                        <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                      )
                                  })}
                                </Select>
                                <FormHelperText error>{formik.touched.cor && formik.errors.cor}</FormHelperText>
                              </FormControl>
                            </Grid>
                        </>
                      }

                    </Grid>

                  }
                  

                  {
                    step >= 1 &&

                    <Grid container spacing={2} >

                        <Grid item xs={12} md={12} sx={{ marginTop: '50px' }}>
                          <SliderComparacaoPrecos precoFipe={formataDinheiroforBanco(consultaFipe?.Valor)} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField
                            className='input'
                            fullWidth
                            id="precovenda"
                            name="precovenda"
                            label="Preço de venda"
                            type="text"
                            value={formik.values.precovenda}
                            onChange={formik.handleChange}
                            error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                            helperText={formik.touched.precovenda && formik.errors.precovenda}
                            onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                            inputProps={{
                              maxLength: 16,
                            }}
                            />
                        </Grid>

                    </Grid>
                  }

                  <div className='box-buttons-steps'>
                    {
                      step > 0 &&

                      <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                      </div>
                    }

                    <div className='box-tbn-steps'> 
                    {/* disabled={ dadosPlaca?.placa ? false : true } */}
                      <ButtonDefault type="submit"  icon="" txt={  step < 1 ? 'Continuar' : 'Finalizar'} /> 
                    </div>
                  </div>


                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Link to={`/cadastros/veiculos/novo`}>Cadastro pelo modelo</Link>
                  </div>
                 

                </form>
                  
            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}