import React, { useEffect, useState, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';

import api from '../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { Editor } from '@tinymce/tinymce-react';

import ButtonDefault from '../../../componentes/ButtonDefault';


export default function ContratoDetalhes() {

    let IconPessoa = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
            <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
            <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
            <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
            </g>
        </svg>
    );
    
    let IconCarro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 47.701 17.828">
        <g id="sedan" transform="translate(0 -160.323)">
        <path id="Caminho_46" data-name="Caminho 46" d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z" transform="translate(-82.091 -118.438)" fill="#4a4a4a"/>
        <path id="Caminho_47" data-name="Caminho 47" d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z" transform="translate(-365.595 -118.437)" fill="#4a4a4a"/>
        <path id="Caminho_48" data-name="Caminho 48" d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z" fill="#4a4a4a"/>
        <path id="Caminho_49" data-name="Caminho 49" d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z" transform="translate(-152.655 -109.039)" fill="#4a4a4a"/>
        </g>
    </svg>
    );

    let IconContrato = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.107 27.107">
        <g id="contract" transform="translate(0 -0.5)">
        <path id="Caminho_50" data-name="Caminho 50" d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_51" data-name="Caminho 51" d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0" transform="translate(-203.11 -105.627)" fill="#4a4a4a"/>
        <path id="Caminho_52" data-name="Caminho 52" d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0" transform="translate(-56.986 -279.609)" fill="#4a4a4a"/>
        <path id="Caminho_53" data-name="Caminho 53" d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -73.785)" fill="#4a4a4a"/>
        <path id="Caminho_54" data-name="Caminho 54" d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -141.696)" fill="#4a4a4a"/>
        <path id="Caminho_55" data-name="Caminho 55" d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -209.591)" fill="#4a4a4a"/>
        <path id="Caminho_56" data-name="Caminho 56" d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0" transform="translate(-233.817 -283.767)" fill="#4a4a4a"/>
        </g>
    </svg>
    );

    let IconModeloMensagem = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
        <g id="mensagem" transform="translate(0 0)">
          <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
          <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
          <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
          <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
        </g>
      </svg>
    );
  
    let IconWhatsApp = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 24 24">
        <g id="whatsapp" transform="translate(0 0)">
          <path id="Caminho_414" data-name="Caminho 414" d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z" fill="#4a4a4a"/>
          <path id="Caminho_415" data-name="Caminho 415" d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let { codigo } = useParams();
    let history = useHistory();
    const editorRef = useRef(null);

    const [dadosContrato, setdadosContrato] = useState([]);
    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
    };


    const detalhesContrato = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        codigo === 'undefined' && history.push("/cadastros/contratos");
    
        api.post('detalhesContrato', {
            "codigo": codigo
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }).then( async response => {
            
            let data = response.data;
            setdadosContrato(response.data);

            formik.setFieldValue('tipo', data.tipo);
            formik.setFieldValue('nome', data.nome);
            formik.setFieldValue('contrato', data.contrato);
          

        }).catch((error) => {
            history.push("/cadastros/contratos");
        });
        
        
    }

    const permissoesUsuario = async()=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let permissoes = await api.post('permissoesUsuario', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
  
      setpermissoesdoUsuario(permissoes.data.contratos);
    }


    const validationSchema = yup.object({
      tipo: yup
      .number('Selecione o tipo')
      .required('Tipo Obrigatório'),
      nome: yup
        .string('Insira o nome do contrato')
        .required('Nome Obrigatório'),
    });

    const formik = useFormik({
      initialValues: {
        tipo: '',
        nome: '',
        contrato: '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
      
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
     
        await api.put(`updateContrato`, {
            "id": dadosContrato.id,
            "tipo": values.tipo,
            "nome": values.nome,
            "contrato": values.contrato,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        setalertSucess(true);        
        setalertSucessMsg(`Dados Atualizados com sucesso!`);        
        setTimeout(() => { setalertSucess(false) }, 6000);
        
      }
    });

    
    useEffect(async ()=>{ 
        await detalhesContrato();
        await permissoesUsuario();
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    let itensSubMenu = [
      { active: "", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
      { active: "", title: "Veículos", rota: "/cadastros/veiculos", icon: <IconCarro/> },
      { active: "active", title: "Contratos", rota: "/cadastros/contratos", icon: <IconContrato/> },
      { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
      { active: "", title: "Listas de Transmissões", rota: "/cadastros/listasTransmissoes", icon: <IconWhatsApp/> },
    ]


    return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Destalhes contrato</title>
        </Helmet>
      </HelmetProvider>


      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>


      
      <div>
        <div className='container-page'>
          <Submenu itens={ itensSubMenu } />
          
          <div className='container'>
          <form method='post' onSubmit={formik.handleSubmit}>

            <Box style={{ marginTop: '20px' }}>
              <Grid container spacing={2} >
                
                <Grid item xs={12} md={12}>
                  <div className='box-content-contrato'>

                    {
                      dadosContrato.length === 0 ?

                      <Grid container spacing={0} >
                        <Grid container spacing={2} >
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                      
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={350} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        
                        </Grid>
                      </Grid>

                      :
                      <>

                      <Grid container spacing={2}>
                        
                        {/* <Grid item container spacing={2} xs={12} md={9}> */}
                          <Grid item xs={12} md={3}>
                            <FormControl sx={{ m: 0, width: '100%'}} >
                              <InputLabel id="label-tipo">Tipo</InputLabel>
                              <Select
                                labelId="label-tipo"
                                id="demo-simple-select"
                                name="tipo"
                                label="Tipo"
                                placeholder='Tipo'
                                value={formik.values.tipo}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                              >
                                <MenuItem value={1}>Venda</MenuItem>
                                <MenuItem value={2}>Compra</MenuItem>
                                <MenuItem value={3}>Troca</MenuItem>
                                <MenuItem value={4}>Distrato</MenuItem>
                              </Select>
                              <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={8} md={7.5}>
                              <TextField
                              fullWidth
                              id="nome"
                              name="nome"
                              label="Nome do contrato"
                              margin="none"
                              type="text"
                              value={formik.values.nome}
                              disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.nome && Boolean(formik.errors.nome)}
                              helperText={formik.touched.nome && formik.errors.nome}
                              />
                          </Grid>

                          <Grid item xs={4} md={1.5}>
                            <div style={{ marginTop: '-10px' }}>
                              <ButtonDefault type="button" onClick={()=>{ formik.handleSubmit() }} icon=""  txt="Salvar" height="55px"/>
                            </div>
                          </Grid>

                          <Grid item xs={12} md={12}>
                              <Editor
                                  apiKey='uziuxnsxme98mwi6oypws1u2x5z2j514pt6rnr9xsxf2zzmm'
                                  onInit={(evt, editor) => editorRef.current = editor}
                                  value={formik.values.contrato}
                                  onEditorChange={(value)=>{ formik.setFieldValue('contrato', value); }}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  init={{
                                    height: 500,
                                    language: 'pt_BR',
                                    menubar: true,
                                    save_onsavecallback: function () { formik.handleSubmit() },
                                    save_enablewhendirty: false,
                                    plugins: [
                                        'pagebreak advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount print preview save'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | pagebreak | print | save',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                              />
                          </Grid>
                        {/* </Grid>

                        <Grid item container spacing={2} xs={12} md={3}>
                          
                        </Grid> */}

                      </Grid>
                      </>
                    
                    }

                  </div>
                </Grid>

             


              </Grid>
            </Box>
          
          </form>
          </div>

        </div>
        <Footer/>
      </div>
        
    </>

    )
}