import React, {useState, useRef, useEffect } from 'react';
import './style.css';

import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';

import { TelMask, mtel } from '../../../vendor/mascaras';

export default function ModalEnvioEstoqueWhatsApp({ comprasSelecionadas, visible, change }) {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  const [contaswhatsapp, setcontaswhatsapp] = useState([]);
  
  const [pessoas, setpessoas] = useState([]);
  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();


  const getContasWhatsApp = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasWhatsApp`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    
    let arrayContas = [];
    
    for await (let conta of response.data) {
        let dados = {
            deviceid: conta.device_id,
            nome: conta.nome
        }    
        
        arrayContas.push(dados);
    }
    
    setcontaswhatsapp(arrayContas);

  }


  const getPessoas = async(pessoaid=null, codigoPessoa=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: `${pessoa.nome.toUpperCase()}`,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);

    if(codigoPessoa){
      //populando fornecedor no select
      let pessoacode = arrayPessoas.find(x => x.codigo === codigoPessoa);
      pessoacode && formik.setFieldValue('pessoa', JSON.stringify(pessoacode));
      setcodigoDetalhesPessoa(codigoPessoa);
      setshowModalDetalhesPessoa(true);

    }else if(pessoaid){
      //populando fornecedor no select
      let fornecedor = arrayPessoas.find(x => x.id === pessoaid);
      fornecedor && formik.setFieldValue('pessoa', JSON.stringify(fornecedor));
      setcodigoDetalhesPessoa(fornecedor.codigo);
    }

  }

  useEffect(async()=>{
    getContasWhatsApp();
    getPessoas();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);


  const validationSchema = yup.object({

    contawhatsapp: yup
      .string('Selecione a conta do WhatsApp')
      .required('Conta do WhatsApp Obrigatória')
      .test({
        message: () => "Selecione a conta do WhatsApp",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),

      pessoa: yup
      .string('Selecione uma pessoa')
      .required('Pessoa Obrigatória')
      .test({
        message: () => "Selecione uma pessoa",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      contawhatsapp: 'null',
      pessoa: 'null',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      setloaderPage(true);
    
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let comprasid = [];
  
      for await (let id of comprasSelecionadas) {
        let dados = { id: id }
        comprasid.push(dados);
      }

      let contawhatsapp = JSON.parse(values.contawhatsapp);
      let pessoa = JSON.parse(values.pessoa);

      await api.post('sendEstoqueWhatsApp', {
          "deviceId": contawhatsapp.deviceid,
          "comprasIds": comprasid,
          "pessoaID": pessoa.id,
      }, 
      {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
          }
      });
    
      formik.resetForm();
      change(false);
      setloaderPage(false);

      Swal.fire(
        'Envio concluído!',
        'Mensagem enviada sucesso!',
        'success'
      )
      
    }
  });


  const handleChangeContawhatsapp = async(event, value, reason, details)=>{
    formik.setFieldValue('contawhatsapp', JSON.stringify(value));
  }

  const handleChangePessoa = async(event, value, reason, details)=>{
    formik.setFieldValue('pessoa', JSON.stringify(value));
    value?.codigo && setcodigoDetalhesPessoa(value.codigo);
  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Envio estoque WhatsApp
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={3} >

            <Grid item xs={12} md={12} lg={12}>
                <FormControl sx={{ m: 0, width: '100%' }} >
                <Autocomplete
                    // disablePortal
                    id="combo-box-contawhats"
                    options={contaswhatsapp}
                    value={JSON.parse(formik.values.contawhatsapp)}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option, value) => option.deviceid === value.deviceid}
                    onChange={handleChangeContawhatsapp}
                    noOptionsText="Nenhuma conta encontrada."
                    sx={{ width: '100%' }}
                    // disabled={!permissoesdoUsuario[3]?.update}
                    renderInput={(params) => <TextField {...params} label="Conta de WhatsApp"  error={formik.touched.contawhatsapp && Boolean(formik.errors.contawhatsapp)}/>}
                />
                <FormHelperText error>{formik.touched.contawhatsapp && formik.errors.contawhatsapp}</FormHelperText>
                </FormControl>
            </Grid>
        
            <Grid container item xs={12} md={12} lg={12} spacing={1.5}>

                <Grid item xs={formik.values.pessoa === 'null' ? 10.4 : 8.8} md={formik.values.pessoa === 'null' ? 10.4 : 8.8} >
                    <FormControl sx={{ m: 0, width: '100%', zIndex: '200' }} >
                    <Autocomplete
                        id="combo-box-pessoa"
                        className='input'
                        autoHighlight
                        options={pessoas}
                        getOptionLabel={(option) => option.nome}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                        )}
                        value={JSON.parse(formik.values.pessoa)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={handleChangePessoa}
                        noOptionsText="Nenhuma pessoa encontrada."
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Pessoa" error={formik.touched.pessoa && Boolean(formik.errors.pessoa)}/>}
                    />
                    <FormHelperText error>{formik.touched.pessoa && formik.errors.pessoa}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={1.6} md={1.6} >
                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                </Grid>

                {
                formik.values.pessoa !== 'null' &&

                <Grid item xs={1.2} md={1} >
                    <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                </Grid>
                }


            </Grid>
        

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Enviar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}