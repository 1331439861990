import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';
import moment from 'moment';


import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonCirclePrimary from '../../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../../componentes/ButtonDefault';

import api from '../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Skeleton from '@mui/material/Skeleton';

import { mplaca, mvalor } from '../../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../../vendor/formatar';

import Swal from 'sweetalert2';

import SliderComparacaoPrecos from '../../../componentes/SliderComparacaoPrecos';
import MultSelectOpcionaisVeiculo from '../../../componentes/MultSelectOpcionaisVeiculo';
import MultSelectCaracteristicasVeiculo from '../../../componentes/MultSelectCaracteristicasVeiculo';

import Fancybox from '../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FsLightbox from 'fslightbox-react';
import ModalUpdateMarcaModeloVeiculo from './modals/modalUpdateMarcaModeloVeiculo';

export default function VeiculosDetalhes(props) {

    let IconPessoa = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
            <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
            <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
            <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
            </g>
        </svg>
    );
    
    let IconCarro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 47.701 17.828">
        <g id="sedan" transform="translate(0 -160.323)">
        <path id="Caminho_46" data-name="Caminho 46" d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z" transform="translate(-82.091 -118.438)" fill="#4a4a4a"/>
        <path id="Caminho_47" data-name="Caminho 47" d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z" transform="translate(-365.595 -118.437)" fill="#4a4a4a"/>
        <path id="Caminho_48" data-name="Caminho 48" d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z" fill="#4a4a4a"/>
        <path id="Caminho_49" data-name="Caminho 49" d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z" transform="translate(-152.655 -109.039)" fill="#4a4a4a"/>
        </g>
    </svg>
    );

    let IconContrato = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.107 27.107">
        <g id="contract" transform="translate(0 -0.5)">
        <path id="Caminho_50" data-name="Caminho 50" d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_51" data-name="Caminho 51" d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0" transform="translate(-203.11 -105.627)" fill="#4a4a4a"/>
        <path id="Caminho_52" data-name="Caminho 52" d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0" transform="translate(-56.986 -279.609)" fill="#4a4a4a"/>
        <path id="Caminho_53" data-name="Caminho 53" d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -73.785)" fill="#4a4a4a"/>
        <path id="Caminho_54" data-name="Caminho 54" d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -141.696)" fill="#4a4a4a"/>
        <path id="Caminho_55" data-name="Caminho 55" d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -209.591)" fill="#4a4a4a"/>
        <path id="Caminho_56" data-name="Caminho 56" d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0" transform="translate(-233.817 -283.767)" fill="#4a4a4a"/>
        </g>
    </svg>
    );
    
    let IconSave = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
        <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
      </svg>
    )

    let IconZoom = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
        <g id="icon" transform="translate(-5.267 -5.266)">
          <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
        </g>
      </svg>
    )

    let IconCloseTimes = props =>(
      <svg id="close" xmlns="http://www.w3.org/2000/svg" width="13.663" height="13.506" viewBox="0 0 13.663 13.506">
        <g id="close_1_" transform="translate(0 0)">
          <path id="Caminho_85" data-name="Caminho 85" d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z" transform="translate(-0.001 -2.444)" fill="#fff"/>
          <path id="Caminho_86" data-name="Caminho 86" d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z" transform="translate(-1.463 -1.844)" fill="#fff"/>
        </g>
      </svg>
    )

    let IconUpload = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="30"viewBox="0 0 38.069 31.819">
        <g id="upload" transform="translate(-0.997 -4.993)">
          <path id="Caminho_87" data-name="Caminho 87" d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z" transform="translate(0)" fill="#fff"/>
          <path id="Caminho_88" data-name="Caminho 88" d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z" transform="translate(2.452 2.181)" fill="#fff"/>
        </g>
      </svg>

    )

    let IconEdit = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 19.987 19.992">
        <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
          <path id="Caminho_344" data-name="Caminho 344" d="M21.1,2.9a3,3,0,0,0-4.27,0L14.91,4.85l4.24,4.24,1.92-1.92A3,3,0,0,0,21.1,2.9Z" fill="#fff"/>
          <path id="Caminho_345" data-name="Caminho 345" d="M13.49,6.27,5.1,14.66a1.15,1.15,0,0,0-.19.26L2.13,20.53A1,1,0,0,0,3.44,21.9l5.61-2.78a1.15,1.15,0,0,0,.26-.19l8.39-8.39Z" fill="#fff"/>
          <path id="Caminho_346" data-name="Caminho 346" d="M21,22H12a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z" fill="#fff"/>
        </g>
      </svg>
    )

    let IconModeloMensagem = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
        <g id="mensagem" transform="translate(0 0)">
          <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
          <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
          <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
          <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let IconWhatsApp = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 24 24">
        <g id="whatsapp" transform="translate(0 0)">
          <path id="Caminho_414" data-name="Caminho 414" d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z" fill="#4a4a4a"/>
          <path id="Caminho_415" data-name="Caminho 415" d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let { codigo } = useParams();
    let history = useHistory();

    const [dadosVeiculo, setdadosVeiculo] = useState([]);

    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [precoPromocionalVencimento, setprecoPromocionalVencimento] = useState(null);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const [combustiveis, setcombustiveis] = useState([]);
    const [cambios, setcambios] = useState([]);
    const [cores, setcores] = useState([]);

    const [opcionais, setopcionais] = useState([]);
    const [opcionaisdoveiculo, setopcionaisdoveiculo] = useState([]);
    const [caracteristicas, setcaracteristicas] = useState([]);
    const [caracteristicasdoveiculo, setcaracteristicasdoveiculo] = useState([]);
    const [fotosDoVeiculo, setfotosDoVeiculo] = useState([]);

    const [progressUploadFotos, setprogressUploadFotos] = useState(0);
    const [showModalUpdateMarcaModeloVeiculo, setshowModalUpdateMarcaModeloVeiculo] = useState(false);


    const handleChangePrecoPromocionalVencimento= (data) => {
      setprecoPromocionalVencimento(data);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
    };

    const getCoresCombustiveisCambios = async()=>{
    
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let cambios = await api.get(`listagemCambios`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });
  
      let combustiveis = await api.get(`listagemCombustiveis`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });
  
      let cores = await api.get(`listagemCores`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      }); 
  
      setcambios(cambios.data);
      setcombustiveis(combustiveis.data);
      setcores(cores.data);
  
    }

    const getOpcionais = async()=>{
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let opcionais = await api.get(`listagemOpcionais`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setopcionais(opcionais.data);

    }

    const getCaracteristicas = async()=>{
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let caracteristicas = await api.get(`listagemCaracteristicas`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setcaracteristicas(caracteristicas.data);

    }

    const getOpcionaisdoVeiculo = async(veiculoid)=>{
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let opcionaisDoVeiculo = await api.post(`listagemOpcionaisVeiculo`,
      {
          "id": veiculoid
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setopcionaisdoveiculo(opcionaisDoVeiculo.data);

    }

    const getCaracteristicasdoVeiculo = async(veiculoid)=>{
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let caracteristicasDoVeiculo = await api.post(`listagemCaracteristicasVeiculo`,
      {
          "id": veiculoid
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setcaracteristicasdoveiculo(caracteristicasDoVeiculo.data);

    }

    const getFotosdoVeiculo = async(veiculoid)=>{
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let fotos = await api.post(`listagemFotosVeiculo`,
      {
          "id": veiculoid
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });
      

      let fotosGaleria = [];

      for await (let foto of fotos.data) {
        fotosGaleria.push(foto.url)
      }
      
      setfotosDoVeiculo(fotos.data);

    }

    const detalhesVeiculo = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        codigo === 'undefined' && history.push("/cadastros/veiculos");
    
        api.post('detalhesVeiculo', {
            "codigo": codigo
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }).then( async response => {
            
            let data = response.data;
            setdadosVeiculo(response.data);

            await getCoresCombustiveisCambios();
    
            data.titulo && formik.setFieldValue('titulo', data.titulo);
            data.combustivelId && formik.setFieldValue('combustivel', data.combustivelId);
            data.cambioId && formik.setFieldValue('cambio', data.cambioId);
            data.portas && formik.setFieldValue('portas', data.portas);
            data.placa && formik.setFieldValue('placa', data.placa);
            data.renavam && formik.setFieldValue('renavam', data.renavam);
            data.chassi && formik.setFieldValue('chassi', data.chassi);
            data.precoVenda && formik.setFieldValue('precovenda', formataDinheiro(data.precoVenda));
            data.corId && formik.setFieldValue('cores', data.corId);
            data.precoPromocional && formik.setFieldValue('precopromocional', formataDinheiro(data.precoPromocional));
            data.precoPromocionalVencimento && setprecoPromocionalVencimento(data.precoPromocionalVencimento);
            data.descricao && formik.setFieldValue('descricao', data.descricao);

            await getOpcionais();
            await getCaracteristicas();
            await getOpcionaisdoVeiculo(data.id);
            await getCaracteristicasdoVeiculo(data.id);
            await getFotosdoVeiculo(data.id);

            
        }).catch((error) => {
            history.push("/cadastros/veiculos");
        });
        
        
    }

    const permissoesUsuario = async()=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let permissoes = await api.post('permissoesUsuario', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
  
      // console.log(permissoes.data.pessoas[3].update);
      setpermissoesdoUsuario(permissoes.data.veiculos);
    }

    const validationSchema = yup.object({
      combustivel: yup
        .number('Selecione o Combustível')
        .required('Combustível Obrigatório'),
      cambio: yup
        .number('Selecione o tipo de Câmbio')
        .required('Câmbio Obrigatório'),
      portas: yup
        .number('Selecione o numero de portas')
        .required('Porta Obrigatória'),
      placa: yup
        .string('Informe a placa do veículo')
        .required('Placa Obrigatória'),
      renavam: yup
        .string('Informe o renavam do veículo')
        .required('Renavam Obrigatório'),
      chassi: yup
        .string('Informe o chassi do veículo')
        .required('Chassi Obrigatório'),
      cores: yup
        .number('Selecione a cor do veículo')
        .required('Cor Obrigatória'),
      precovenda: yup
        .string('Informe o preço do veículo')
        .required('Preço de venda Obrigatório'),

     
    });

    const formik = useFormik({
      initialValues: {
        titulo: '',
        combustivel: '',
        cambio: '',
        portas: '',
        placa: '',
        renavam: '',
        chassi: '',
        precovenda: '',
        cores: '',
        precopromocional: '',
        descricao: '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
      
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let promocionalVencimento = moment(moment(precoPromocionalVencimento, 'DD-MM-YYYY')).format('DD/MM/YYYY');

        let precodeVenda = formataDinheiroforBanco(values.precovenda);
        let precopromocional = formataDinheiroforBanco(values.precopromocional);

        await api.put(`updateVeiculo`, {
            "id": dadosVeiculo.id,
            "cor_id": values.cores,
            "precoVenda": precodeVenda,
            "precoPromocional": values.precopromocional ? precopromocional : null,
            "precoPromocionalVencimento": precoPromocionalVencimento === null ? null : promocionalVencimento,
            "titulo": values.titulo,
            "combustivel_id": values.combustivel,
            "cambio_id": values.cambio,
            "placa": values.placa,
            "renavam": values.renavam,
            "chassi": values.chassi,
            "portas": values.portas,
            "descricao": values.descricao ? values.descricao : null,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        setalertSucess(true);        
        setalertSucessMsg(`Dados Atualizados com sucesso!`);    
        setTimeout(() => { setalertSucess(false) }, 6000);   

      }
    });

    
    useEffect(async ()=>{ 
        await detalhesVeiculo();
        await permissoesUsuario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    

    let itensSubMenu = [
        { active: "", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
        { active: "active", title: "Veículos", rota: "/cadastros/veiculos", icon: <IconCarro/> },
        { active: "", title: "Contratos", rota: "/cadastros/contratos", icon: <IconContrato/> },
        { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
        { active: "", title: "Listas de Transmissões", rota: "/cadastros/listasTransmissoes", icon: <IconWhatsApp/> },
    ];
   
    const Input = styled('input')({
      display: 'none',
    });

    let handleFotoVeiculo = async(e)=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let imagens = e.target.files;

      let formData = new FormData();
      formData.append('id', dadosVeiculo.id);


      if(imagens.length > 7){
        Swal.fire(
          'Ops, Limite por vez atingido!',
          'Você só pode enviar 7 imagens por vez, e inserir até 20 fotos no veículo.',
          'warning'
        )
      }else{

        for await (let imagem of imagens) {

          if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
            formData.append('imagens', imagem);
          }else{
            setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
            setalertError(true);
            setTimeout(() => { setalertError(false) }, 6000);   
          }
          
        }

        let response = await api.put("uploadFotosVeiculo", formData, {
          headers: { 
            "Content-type": "multipart/form-data",
            "Authorization": `Bearer ${auth.token}`
          },
          onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));
            setprogressUploadFotos(progress)
          },
        });

        if(response.data.error_code === "MAXIMUM_FILES"){
          setalertErrorMsg(response.data.message)
          setalertError(true);
          setTimeout(() => { setalertError(false) }, 6000);
        }

        // await new Promise(r => setTimeout(r, imagens.length * 150));
        setprogressUploadFotos(0);
        getFotosdoVeiculo(dadosVeiculo.id);
      }
      

    }

    const handleDeleteFoto = async(idFoto)=>{
    
      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar a Foto selecionada?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
          
          await api.delete('deleteFotoVeiculo', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "id": idFoto, "veiculoId": dadosVeiculo.id }
          });

          await getFotosdoVeiculo(dadosVeiculo.id);
  
          Swal.fire(
            'Deletado!',
            'Foto deletada com sucesso!',
            'success'
          )
        }
      })
  
    }

    const handleDragFoto = async(result)=>{
      

      if(permissoesdoUsuario[3]?.update){

        const items = Array.from(fotosDoVeiculo);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let dadosArray = [];

        for await (let [key, item] of  Object.entries(items)) {
          let dados = {
            id: item.id,
            ordem: parseInt(key)
          }

          dadosArray.push(dados);

        }

        setfotosDoVeiculo(items);

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.put(`updateOrdemFotosVeiculo`,
        {
            "produtoId": dadosVeiculo.id,
            "dados": dadosArray
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        })

      }else{
        setalertErrorMsg('Você não possui autorização para modificar a ordem das fotos.')
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
      }
 
    }

    return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Destalhes veículo</title>
        </Helmet>
      </HelmetProvider>


      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <ModalUpdateMarcaModeloVeiculo codigo={codigo} visible={showModalUpdateMarcaModeloVeiculo} change={setshowModalUpdateMarcaModeloVeiculo} />

      <div>
        <div className='container-page'>
          <Submenu itens={ itensSubMenu } />
          
          <div className='container'>
          <form method='post' onSubmit={formik.handleSubmit}>

            <Box style={{ marginTop: '20px' }}>
              <Grid container spacing={2} >
                

                <Grid item xs={12} md={12}>
                  <div className='box-content'>
                    
                    <div className='header-box-content'>
                      <h3 className='title'>{dadosVeiculo?.placa}</h3>
                      <ButtonCirclePrimary icon={<IconEdit/>} onClick={()=>{ setshowModalUpdateMarcaModeloVeiculo(true) }}/>
                    </div>
                    <Grid container item spacing={0} xs={12} md={12} direction="row">
                      <Grid item xs={12} md={4}>
                        <div className='box-left-info-car'>
                          <div className='box-foto-marca' style={{ backgroundImage: `url(${dadosVeiculo?.marcaLogo})` }}></div>
                          
                          <aside>
                            <p>{dadosVeiculo?.marca}</p>
                            <p>{dadosVeiculo?.modelo}</p>
                          </aside>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                          <div className='box-right-info-car'>
                              <aside>
                                <p>{dadosVeiculo?.ano} / {dadosVeiculo?.anoFabricacao}</p>
                                <p>{dadosVeiculo?.cor && dadosVeiculo?.cor.toUpperCase()}</p>
                              </aside>
                              
                              <article>
                                <p><b>CÓDIGO FIPE:</b> {dadosVeiculo?.fipeCodigo}</p>
                              </article>
                          </div>
                      </Grid>
                    </Grid>
                    

                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className='box-content'>
                    
                  {
                    cores.length === 0?

                    <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                      </Grid>
                    </Grid>
                    
                    :
                    <>
                    <div className='header-box-content'>
                      <h3 className='title'>Detalhes</h3>
                      {
                        permissoesdoUsuario[3]?.update &&
                        <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                      }
                    </div>

                    <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          id="titulo"
                          name="titulo"
                          label="Título (site)"
                          margin="none"
                          type="text"
                          value={formik.values.titulo}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                          helperText={formik.touched.titulo && formik.errors.titulo}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={2.5} >
                        <FormControl sx={{ m: 0, width: '100%' }} >
                          <InputLabel id="label-combustivel">Combustível</InputLabel>
                          <Select
                            labelId="label-combustivel"
                            id="combustivel-select"
                            name="combustivel"
                            label="Combustível"
                            placeholder='Combustível'
                            value={formik.values.combustivel}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.combustivel && Boolean(formik.errors.combustivel)}
                          >
                            {combustiveis.map((v, k) =>{
                                return (
                                  <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                )
                            })}
                          </Select>
                          <FormHelperText error>{formik.touched.combustivel && formik.errors.combustivel}</FormHelperText>
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs={12} md={2} >
                        <FormControl sx={{ m: 0, width: '100%' }} >
                          <InputLabel id="label-cambio">Câmbio</InputLabel>
                          <Select
                            labelId="label-cambio"
                            id="cambio-select"
                            name="cambio"
                            label="Câmbio"
                            placeholder='Câmbio'
                            value={formik.values.cambio}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.cambio && Boolean(formik.errors.cambio)}
                          >
                            {cambios.map((v, k) =>{
                                return (
                                  <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                )
                            })}
                          </Select>
                          <FormHelperText error>{formik.touched.cambio && formik.errors.cambio}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={1.5} >
                        <FormControl sx={{ m: 0, width: '100%' }} >
                          <InputLabel id="label-portas">Portas</InputLabel>
                          <Select
                            labelId="label-portas"
                            id="portas-select"
                            name="portas"
                            label="Portas"
                            placeholder='Portas'
                            value={formik.values.portas}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.portas && Boolean(formik.errors.portas)}
                          >
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                          <FormHelperText error>{formik.touched.portas && formik.errors.portas}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} >
                        <TextField
                          fullWidth
                          id="placa"
                          name="placa"
                          label="Placa"
                          margin="none"
                          type="text"
                          value={formik.values.placa}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.placa && Boolean(formik.errors.placa)}
                          helperText={formik.touched.placa && formik.errors.placa}

                          onKeyUp={()=>{ formik.setFieldValue('placa', mplaca(formik.values.placa)) }}
                          inputProps={{
                            // inputComponent: placaMask,
                            maxLength: 7,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} >
                        <TextField
                          fullWidth
                          id="renavam"
                          name="renavam"
                          label="Renavam"
                          margin="none"
                          type="text"
                          value={formik.values.renavam}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.renavam && Boolean(formik.errors.renavam)}
                          helperText={formik.touched.renavam && formik.errors.renavam}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} >
                        <TextField
                          fullWidth
                          id="chassi"
                          name="chassi"
                          label="Chassi"
                          margin="none"
                          type="text"
                          value={formik.values.chassi}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.chassi && Boolean(formik.errors.chassi)}
                          helperText={formik.touched.chassi && formik.errors.chassi}
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={2} >
                        <FormControlLabel control={
                          <Switch
                            disabled={!permissoesdoUsuario[3]?.update}
                            checked={formik.values.chassi ? true : false}
                            // onChange={(event)=>{ handlePrincipalTelefone(event, params.id) }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } label="Label" />
                      </Grid> */}
                      
                      <Grid item xs={12} md={12} sx={{ marginTop: '13px', marginBottom: '13px' }}>
                        <div className='border-divider-full-width'></div>
                      </Grid>


                      <Grid item xs={12} md={2} >
                        <FormControl sx={{ m: 0, width: '100%' }} >
                          <InputLabel id="label-cores">Cor</InputLabel>
                          <Select
                            labelId="label-cores"
                            id="cores-select"
                            name="cores"
                            label="Cor"
                            placeholder='Cor'
                            value={formik.values.cores}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.cores && Boolean(formik.errors.cores)}
                          >
                            {cores.map((v, k) =>{
                                return (
                                  <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                )
                            })}
                          </Select>
                          <FormHelperText error>{formik.touched.cores && formik.errors.cores}</FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} >
                        <TextField
                          fullWidth
                          id="precovenda"
                          name="precovenda"
                          label="Preço de venda"
                          margin="none"
                          type="text"
                          value={formik.values.precovenda}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                          helperText={formik.touched.precovenda && formik.errors.precovenda}
                          onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                          inputProps={{
                            maxLength: 16,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} >
                        <TextField
                          fullWidth
                          id="precopromocional"
                          name="precopromocional"
                          label="Preço promocional"
                          margin="none"
                          type="text"
                          value={formik.values.precopromocional}
                          disabled={!permissoesdoUsuario[3]?.update}
                          onChange={formik.handleChange}
                          error={formik.touched.precopromocional && Boolean(formik.errors.precopromocional)}
                          helperText={formik.touched.precopromocional && formik.errors.precopromocional}
                          onKeyUp={()=>{ formik.setFieldValue('precopromocional', mvalor(formik.values.precopromocional)) }}
                          inputProps={{
                            maxLength: 13,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                          <DesktopDatePicker
                            label="Vencimento da promoção"
                            inputFormat="dd/MM/yyyy"
                            name="precopromocionalvencimento"
                            value={precoPromocionalVencimento}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={handleChangePrecoPromocionalVencimento}
                            allowSameDateSelection={true}
                            renderInput={(params) => <TextField autoComplete='off' style={{ width: '100%', marginTop: '0px'}} {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} md={4} sx={{ marginTop: '15px' }}>
                        <SliderComparacaoPrecos precoFipe={dadosVeiculo?.precoFipe} />
                      </Grid>
                     

                    </Grid>
                    </>
                  }

                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className='box-content'>
                    
                    <div className='header-box-content'>
                      <h3 className='title'>Opcionais</h3>
                    </div>
                    
                    <Grid container item spacing={2} xs={12} md={12} direction="row" style={{marginTop: '-5px'}}>
                    {opcionais.map((v, k) =>{
                        return (
                          <Grid item xs={12} md={6} key={k}>
                            <MultSelectOpcionaisVeiculo idVeiculo={dadosVeiculo.id} dados={v} opcionaisDoVeiculo={opcionaisdoveiculo} atualizar={getOpcionaisdoVeiculo} disabled={!permissoesdoUsuario[3]?.update}/>
                          </Grid>
                        )
                    })}
                    </Grid>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className='box-content'>
                    
                  {
                    cores.length === 0 ?
                      
                    <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                      <Grid item xs={12} md={12}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={240} style={{ borderRadius: '5px' }}/>
                      </Grid>
                    </Grid>

                    :

                    <>
                    <div className='header-box-content'>
                      <h3 className='title'>Características</h3>
                    </div>
                    
                    <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ height: '256px', marginTop: '-5px' }}>
                        <Grid item xs={12} md={12}>
                          <MultSelectCaracteristicasVeiculo idVeiculo={dadosVeiculo.id} dados={caracteristicas} caracteristicasDoVeiculo={caracteristicasdoveiculo} atualizar={getCaracteristicasdoVeiculo} disabled={!permissoesdoUsuario[3]?.update}/>
                        </Grid>
                    </Grid>
                    </>
                  }

                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className='box-content'>
                    
                  {
                    cores.length === 0 ?
                      
                    <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                      <Grid item xs={12} md={12}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={240} style={{ borderRadius: '5px' }}/>
                      </Grid>
                    </Grid>

                    :
                    <>
                    <div className='header-box-content'>
                      <h3 className='title'>Descrição</h3>
                      {
                        permissoesdoUsuario[3]?.update &&
                        <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                      }
                    </div>

                    <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                        <Grid item xs={12} md={12} >
                          <TextField
                            fullWidth
                            id="descricao"
                            name="descricao"
                            margin="none"
                            multiline
                            disabled={!permissoesdoUsuario[3]?.update}
                            rows={9}
                            defaultValue={formik.values.descricao}
                            onChange={formik.handleChange}
                            error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                            helperText={formik.touched.descricao && formik.errors.descricao}
                          />
                        </Grid>
                    </Grid>
                    </>

                  }
                    

                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className='box-content'>

                  {
                    cores.length === 0 ?

                    <Grid container spacing={2} item xs={12} md={12} style={{ marginTop: '10px' }}>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                    </Grid>
                    
                    :

                    <>
                    <div className='header-box-content'>
                      <h3 className='title'>Fotos</h3>
                    </div>

                    <div className='box-fotos-detalhes-veiculos'>

                    <Fancybox>
                      <DragDropContext onDragEnd={handleDragFoto}>
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(provided) => (
                            <aside {...provided.droppableProps} ref={provided.innerRef} id={props.galleryID}>
                              {fotosDoVeiculo.map((v, k) =>{
                                return (
                                  <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                    {(provided) => (
                                      <div className='box-element-foto' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className='element-foto' style={{ backgroundImage: `url(${v.url})` }}>
                                          
                                          {
                                            permissoesdoUsuario[3]?.update &&

                                            <div className='box-deleteFotoDVeiculo'>
                                              <button type='button' onClick={()=>{ handleDeleteFoto(v.id) }} className='deleteFotoDVeiculo'><IconCloseTimes/></button>
                                            </div>
                                          }

                                          <div className='box-abrirFotoDVeiculo'>
                                              <button type='button' data-fancybox="gallery" href={v.url}>
                                                <IconZoom/>
                                              </button>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </aside>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Fancybox>
                      
                      {
                          permissoesdoUsuario[2]?.insert &&
                          
                          <div className='box-button-upload'>
                            <div style={{ width: '220px' }}>

                              {
                                progressUploadFotos === 0 &&

                                <label htmlFor="button-file-fotos-veiculo">
                                  <Input accept="image/png, image/gif, image/jpeg" id="button-file-fotos-veiculo" multiple onChange={handleFotoVeiculo} type="file" />
                                  <ButtonDefault type="button" icon={<IconUpload/>} component="span" txt="Adicionar fotos"  />
                                </label>
                              }
                              
                              {
                                progressUploadFotos > 0 && 
                                <LinearProgress variant="determinate" value={progressUploadFotos} />
                              }

                            </div>
                            <p>Formatos aceitos: .JPG, .JPEG, .PNG | Tamanho máximo: 5MB</p>
                          </div>
                      }

                    </div>
                    </>
                  }

                  </div>
                </Grid>

                
                

              </Grid>
            </Box>
          
          </form>
          </div>

        </div>
        <Footer/>
      </div>
        
    </>

    )
}