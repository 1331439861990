import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config

import api from '../../services/api';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import Footer from '../../componentes/Footer';
import Submenu from '../../componentes/SubMenu';

import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";

import ButtonSquadPrimary from '../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../componentes/InputSearchActions';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import ButtonDefault from '../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../componentes/ButtonOutlineSecondary';

import SubMenusFinancas from './submenusFinancas';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  addWeeks,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  format,
  isWeekend
} from "date-fns";

import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker, Calendar, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { formataDinheiroforBanco, formataDinheiro } from '../../vendor/formatar';

import DocumentPDFfluxo from './documentPDFfluxo';

export default function FluxoCaixa() {

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconPessoaCancel = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 312.858 306.449">
      <path id="user_1_" data-name="user (1)" d="M238.867,143.97a73.556,73.556,0,0,0-35.6,9.14,114.809,114.809,0,0,0-32.255-17.6,96.246,96.246,0,0,0,20.239-40.263c3.921-16.946,2.821-34.358-3.1-49.028-10.332-25.608-33.585-40.541-63.8-40.97h-.208c-30.212.43-53.464,15.362-63.8,40.97-5.919,14.671-7.019,32.082-3.1,49.029a96.241,96.241,0,0,0,20.236,40.26c-25.212,9-45.505,25.418-59.135,47.992C4.02,207.249-2.234,237.279.747,268.06A7.332,7.332,0,0,0,4.1,273.532c35.231,22.509,71.1,35.193,106.607,37.7q6.614.467,13.244.467c31.078,0,62.428-7.684,93.377-22.9A74.014,74.014,0,1,0,238.867,143.97ZM73.954,51.709c8.121-20.128,25.982-31.415,50.3-31.792,24.319.376,42.179,11.664,50.3,31.792,10.219,25.327,2.015,58.228-19.949,80.01-10.236,10.152-20.448,15.3-30.351,15.3s-20.115-5.148-30.352-15.3C71.938,109.937,63.735,77.036,73.954,51.709ZM15.042,263.1C10.873,206.363,39.566,161.484,89.082,147.18c11.4,9.628,23.2,14.5,35.173,14.5s23.776-4.877,35.173-14.5a103.183,103.183,0,0,1,31.148,14.76,73.94,73.94,0,0,0,9.145,118.828C137.649,307.506,77.08,301.689,15.041,263.1Zm223.825,14.234a59.347,59.347,0,1,1,59.347-59.347,59.414,59.414,0,0,1-59.347,59.347ZM269.1,198.118l-19.864,19.865L269.1,237.847a7.333,7.333,0,1,1-10.37,10.371l-19.864-19.864L219,248.218a7.333,7.333,0,1,1-10.37-10.371L228.5,217.983l-19.864-19.864A7.333,7.333,0,0,1,219,187.748l19.864,19.864,19.864-19.865A7.333,7.333,0,1,1,269.1,198.118Z" transform="translate(-0.02 -5.25)" fill="#4a4a4a"/>
    </svg>
  )

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconDownSeta = props =>(
    <svg id="up" xmlns="http://www.w3.org/2000/svg" width="21.737" height="21.737" viewBox="0 0 21.737 21.737">
      <g id="Grupo_301" data-name="Grupo 301">
        <path id="Caminho_151" data-name="Caminho 151" d="M10.869,21.737A10.869,10.869,0,1,1,21.737,10.869,10.863,10.863,0,0,1,10.869,21.737Zm4.045-11.665H12.879V5.755a.725.725,0,0,0-.725-.725H9.583a.725.725,0,0,0-.725.725v4.317H6.824a.725.725,0,0,0-.571,1.17L10.3,16.428a.725.725,0,0,0,1.143,0l4.045-5.185A.725.725,0,0,0,14.914,10.072Z" fill="#f44236"/>
      </g>
    </svg>
  )

  let IconUpSeta = props =>(
    <svg id="up" xmlns="http://www.w3.org/2000/svg" width="21.737" height="21.737" viewBox="0 0 21.737 21.737">
      <g id="Grupo_301" data-name="Grupo 301">
        <path id="Caminho_151" data-name="Caminho 151" d="M10.869,0A10.869,10.869,0,1,0,21.737,10.869,10.863,10.863,0,0,0,10.869,0Zm4.045,11.665H12.879v4.317a.725.725,0,0,1-.725.725H9.583a.725.725,0,0,1-.725-.725V11.665H6.824a.725.725,0,0,1-.571-1.17L10.3,5.31a.725.725,0,0,1,1.143,0l4.045,5.185A.725.725,0,0,1,14.914,11.665Z" fill="#0554c2"/>
      </g>
    </svg>
  )

  let IconNoFluxo = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 91.356 91.353">
      <g id="money-flow_1_" data-name="money-flow (1)" transform="translate(-1.997 -1.998)">
        <path id="Caminho_234" data-name="Caminho 234" d="M22.786,19.786a3.262,3.262,0,0,1,3.262,3.262h6.524a9.786,9.786,0,0,0-6.524-9.186V10H19.524v3.862a9.763,9.763,0,0,0,3.262,18.972A3.262,3.262,0,1,1,19.524,36.1H13a9.786,9.786,0,0,0,6.524,9.186v3.862h6.524V45.281A9.763,9.763,0,0,0,22.786,26.31a3.262,3.262,0,0,1,0-6.524Z" transform="translate(24.889 18.1)" fill="#4a4a4a"/>
        <path id="Caminho_235" data-name="Caminho 235" d="M79.423,55.038A32.515,32.515,0,0,0,40.309,15.923,19.572,19.572,0,1,0,15.922,40.31,32.515,32.515,0,0,0,55.04,79.421,19.572,19.572,0,1,0,79.423,55.038ZM8.531,21.576a13.015,13.015,0,0,1,25.551-3.52A32.773,32.773,0,0,0,18.059,34.08a13.028,13.028,0,0,1-9.528-12.5Zm13.048,26.1a26.1,26.1,0,1,1,26.1,26.1,26.1,26.1,0,0,1-26.1-26.1ZM73.77,86.816a13.028,13.028,0,0,1-12.5-9.528A32.773,32.773,0,0,0,77.29,61.265a13.015,13.015,0,0,1-3.52,25.551Z" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_236" data-name="Caminho 236" d="M32.742,21.865l4.612,4.612,9.786-9.786a3.262,3.262,0,0,0,0-4.612L37.354,2.293,32.742,6.905l4.218,4.218H22v6.524H36.959Z" transform="translate(45.246 0.667)" fill="#4a4a4a"/>
        <path id="Caminho_237" data-name="Caminho 237" d="M17.354,26.905l-4.612-4.612L2.955,32.079a3.262,3.262,0,0,0,0,4.612l9.786,9.786,4.612-4.612-4.218-4.218H28.1V31.123H13.136Z" transform="translate(0.007 45.907)" fill="#4a4a4a"/>
      </g>
    </svg>
  )

  let IconCalendar = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25"viewBox="0 0 33.027 32.443">
      <g id="calendar" transform="translate(0 -4.289)">
        <g id="_x31_5_38_" transform="translate(0 4.289)">
          <g id="Grupo_344" data-name="Grupo 344" transform="translate(0 0)">
            <path id="Caminho_269" data-name="Caminho 269" d="M100.715,12.292h.473a1.3,1.3,0,0,0,1.3-1.3V6.062a1.3,1.3,0,0,0-1.3-1.3h-.473a1.3,1.3,0,0,0-1.3,1.3v4.93A1.3,1.3,0,0,0,100.715,12.292Z" transform="translate(-92.644 -4.73)" fill="#fff"/>
            <path id="Caminho_270" data-name="Caminho 270" d="M345.581,11.819h.473a1.3,1.3,0,0,0,1.3-1.3V5.589a1.3,1.3,0,0,0-1.3-1.3h-.473a1.3,1.3,0,0,0-1.3,1.3v4.93A1.3,1.3,0,0,0,345.581,11.819Z" transform="translate(-320.834 -4.289)" fill="#fff"/>
            <path id="Caminho_271" data-name="Caminho 271" d="M30.914,43.558H27.577v3.9a2.092,2.092,0,0,1-2.357,2.017h-.473a2.36,2.36,0,0,1-2.357-2.357V43.558H10.9v3.588A2.36,2.36,0,0,1,8.543,49.5H8.071a2.36,2.36,0,0,1-2.357-2.357V43.558h-3.6A2.116,2.116,0,0,0,0,45.672V71.213a2.116,2.116,0,0,0,2.114,2.114h28.8a2.116,2.116,0,0,0,2.114-2.114V45.672A2.116,2.116,0,0,0,30.914,43.558Zm0,27.655H2.114V51.925h28.8V71.213Z" transform="translate(0 -40.884)" fill="#fff"/>
            <path id="Caminho_272" data-name="Caminho 272" d="M254.77,208.986h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,254.77,208.986Z" transform="translate(-237.166 -191.475)" fill="#fff"/>
            <path id="Caminho_273" data-name="Caminho 273" d="M345.715,208.986h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,345.715,208.986Z" transform="translate(-321.917 -191.475)" fill="#fff"/>
            <path id="Caminho_274" data-name="Caminho 274" d="M72.883,287.985h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,72.883,287.985Z" transform="translate(-67.666 -265.094)" fill="#fff"/>
            <path id="Caminho_275" data-name="Caminho 275" d="M163.827,287.985h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,163.827,287.985Z" transform="translate(-152.416 -265.094)" fill="#fff"/>
            <path id="Caminho_276" data-name="Caminho 276" d="M254.771,287.985h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,254.771,287.985Z" transform="translate(-237.166 -265.094)" fill="#fff"/>
            <path id="Caminho_277" data-name="Caminho 277" d="M345.715,287.985h3.8a.272.272,0,0,0,.272-.272v-3.286a.272.272,0,0,0-.272-.272h-3.8a.272.272,0,0,0-.272.272v3.286A.272.272,0,0,0,345.715,287.985Z" transform="translate(-321.917 -265.094)" fill="#fff"/>
            <path id="Caminho_278" data-name="Caminho 278" d="M76.68,363.154h-3.8a.272.272,0,0,0-.272.272v3.286a.272.272,0,0,0,.272.272h3.8a.272.272,0,0,0,.272-.272v-3.286A.273.273,0,0,0,76.68,363.154Z" transform="translate(-67.667 -338.714)" fill="#fff"/>
            <path id="Caminho_279" data-name="Caminho 279" d="M167.624,363.154h-3.8a.272.272,0,0,0-.272.272v3.286a.272.272,0,0,0,.272.272h3.8a.272.272,0,0,0,.272-.272v-3.286A.272.272,0,0,0,167.624,363.154Z" transform="translate(-152.417 -338.714)" fill="#fff"/>
            <path id="Caminho_280" data-name="Caminho 280" d="M258.568,363.154h-3.8a.272.272,0,0,0-.272.272v3.286a.272.272,0,0,0,.272.272h3.8a.272.272,0,0,0,.272-.272v-3.286A.272.272,0,0,0,258.568,363.154Z" transform="translate(-237.167 -338.714)" fill="#fff"/>
            <path id="Caminho_281" data-name="Caminho 281" d="M349.511,363.154h-3.8a.272.272,0,0,0-.272.272v3.286a.272.272,0,0,0,.272.272h3.8a.272.272,0,0,0,.272-.272v-3.286A.272.272,0,0,0,349.511,363.154Z" transform="translate(-321.917 -338.714)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )


  let history = useHistory();
  let csvInstance = useRef();

  const [loaderPage, setloaderPage] = useState(false);

  const [fluxos, setfluxos] = useState([]);
  const [fluxosSelecionados, setfluxosSelecionados] = useState([]);
  const [pageSizeDataGridFluxos, setpageSizeDataGridFluxos] = useState(10);
  const [loadingDataGridFluxo, setloadingDataGridFluxo] = useState(true);

  const [filtroData, setfiltroData] = useState([
    {
      startDate: new Date(), //new Date(),
      endDate: new Date(), //addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [showModalfiltroData, setshowModalfiltroData] = useState(false);
  const [listagemFluxoExport, setlistagemFluxoExport] = useState([]);

  const [resumoAnteriorTotalCaixas, setresumoAnteriorTotalCaixas] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoAnteriorTotalBancos, setresumoAnteriorTotalBancos] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoAnteriorTotalAReceber, setresumoAnteriorTotalAReceber] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoAnteriorTotalApagar, setresumoAnteriorTotalApagar] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoAnteriorTotalEstoque, setresumoAnteriorTotalEstoque] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoAnteriorTotalSaldo, setresumoAnteriorTotalSaldo] = useState({ total: 0, entradas: 0, saidas: 0});

  const [resumoFinalTotalCaixas, setresumoFinalTotalCaixas] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoFinalTotalBancos, setresumoFinalTotalBancos] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoFinalTotalAReceber, setresumoFinalTotalAReceber] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoFinalTotalApagar, setresumoFinalTotalApagar] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoFinalTotalEstoque, setresumoFinalTotalEstoque] = useState({ total: 0, entradas: 0, saidas: 0});
  const [resumoFinalTotalSaldo, setresumoFinalTotalSaldo] = useState({ total: 0, entradas: 0, saidas: 0});

  
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const getFluxos = async(termo='', dataInicial=null, dataFinal=null)=>{
    setloadingDataGridFluxo(true);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let fluxo = await api.get(`listagemFluxo?termo=${termo}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });


    // //SALDO ANTERIOR
    // let fluxoAnterior = await api.get(`listagemFluxo?termo=${termo}&dataInicial=${moment('1994-10-13 23:59:00').format('YYYY-MM-DD 23:59:59')}&dataFinal=${moment(dataInicial).subtract(1, 'days').format('YYYY-MM-DD 23:59:59')}`, {
    //   headers: {
    //     "Content-type": "application/json",
    //     "Authorization": `Bearer ${auth.token}`
    //   }
    // });

    // let totalAnteriorCaixas = 0;
    // let totalAnteriorBancos = 0;
    // let totalAnteriorAReceber = 0;
    // let totalAnteriorApagar = 0;
    // let totalAnteriorEstoque = 0;
    // let totalAnteriorSaldo = 0;

   
    // for await (let fluxo of fluxoAnterior.data) {
    //   totalAnteriorCaixas += Number(fluxo.caixas);
    //   totalAnteriorBancos += Number(fluxo.bancos);
    //   totalAnteriorAReceber += Number(fluxo.receber);
    //   totalAnteriorApagar += Number(fluxo.pagar);
    //   totalAnteriorEstoque += Number(fluxo.estoque);
    //   totalAnteriorSaldo += Number(fluxo.saldo);
    // }

    // setresumoAnteriorTotalCaixas({ total: totalAnteriorCaixas});
    // setresumoAnteriorTotalBancos({ total: totalAnteriorBancos});
    // setresumoAnteriorTotalAReceber({ total:  totalAnteriorAReceber});
    // setresumoAnteriorTotalApagar({ total:  totalAnteriorApagar});
    // setresumoAnteriorTotalEstoque({ total:  totalAnteriorEstoque});
    // setresumoAnteriorTotalSaldo({ total: totalAnteriorSaldo});

    

    // //SALDO FINAL
    // let fluxoFinal = await api.get(`listagemFluxo?termo=${termo}&dataInicial=${moment('1994-10-13 23:59:00').format('YYYY-MM-DD 23:59:59')}&dataFinal=${moment(dataFinal).format('YYYY-MM-DD 23:59:59')}`, {
    //   headers: {
    //     "Content-type": "application/json",
    //     "Authorization": `Bearer ${auth.token}`
    //   }
    // });

    // let totalFinalCaixas = 0;
    // let totalFinalBancos = 0;
    // let totalFinalAReceber = 0;
    // let totalFinalApagar = 0;
    // let totalFinalEstoque = 0;
    // let totalFinalSaldo = 0;

   
    // for await (let fluxo of fluxoFinal.data) {
    //   totalFinalCaixas += Number(fluxo.caixas);
    //   totalFinalBancos += Number(fluxo.bancos);
    //   totalFinalAReceber += Number(fluxo.receber);
    //   totalFinalApagar += Number(fluxo.pagar);
    //   totalFinalEstoque += Number(fluxo.estoque);
    //   totalFinalSaldo += Number(fluxo.saldo);;
    // }

    // setresumoFinalTotalCaixas({ total: totalFinalCaixas});
    // setresumoFinalTotalBancos({ total: totalFinalBancos});
    // setresumoFinalTotalAReceber({ total:  totalFinalAReceber});
    // setresumoFinalTotalApagar({ total:  totalFinalApagar});
    // setresumoFinalTotalEstoque({ total:  totalFinalEstoque});
    // setresumoFinalTotalSaldo({ total: totalFinalSaldo});

    // setfluxos(fluxo.data);
    console.log(fluxo.data);
    setloadingDataGridFluxo(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.pessoas);
  }

  

  useEffect(()=>{
    getFluxos('', moment(filtroData[0]?.startDate).format('YYYY-MM-DD 00:00:00'), moment(filtroData[0]?.endDate).format('YYYY-MM-DD 23:59:59') );
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const handleSearchFluxos = async(event)=>{
    let termo = event.target.value;

    if(termo.length >= 3){
      getFluxos(termo, moment(filtroData[0]?.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(filtroData[0]?.endDate).format('YYYY-MM-DD 23:59:59'));
    }else{
      getFluxos('', moment(filtroData[0]?.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(filtroData[0]?.endDate).format('YYYY-MM-DD 23:59:59') );
    }
  }

  const handleFluxoFiltroData = async()=>{
    getFluxos('', moment(filtroData[0]?.startDate).format('YYYY-MM-DD HH:mm:ss'), moment(filtroData[0]?.endDate).format('YYYY-MM-DD 23:59:59') );
    setshowModalfiltroData(false);
  }


  const columnsDataGridFluxos = [
    // { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'operacao',
      headerName: 'Operação',
      type: 'string',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => {//renderiza componente
          switch (params.value) {
              case 1:
                  return (<p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}><IconUpSeta/> <span style={{marginLeft: '6px'}}>Crédito</span></p>)
                  break;

              case 2:
                  return (<p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}><IconDownSeta/> <span style={{marginLeft: '6px'}}>Débito</span></p>)
                  break;
          }
      },
    },
    {
      field: 'data',
      headerName: 'Data',
      type: 'string',
      flex: 1,
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
      return moment(params.value).format('DD/MM/YYYY');
      },
    },
    {
        field: 'contaOrcamentaria',
        headerName: 'Conta Orçamentária',
        type: 'string',
        flex: 1,
        minWidth: 150,
        editable: false,
        renderCell: (params) => ( //renderiza componente
          <p>{params.value}</p>
        ),
    },
    {
        field: 'descricao',
        headerName: 'Descrição',
        type: 'string',
        flex: 2,
        minWidth: 250,
        editable: false,
        renderCell: (params) => ( //renderiza componente
          <p title={params.value}>{params.value}</p>
        ),
    },
    {
      field: 'caixas',
      headerName: 'Caixas',
      type: 'string',
      flex: 1,
      minWidth: 120,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value);
      },
    },
    {
      field: 'bancos',
      headerName: 'Bancos',
      type: 'string',
      flex: 1,
      minWidth: 120,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value);
      },
    },
    {
        field: 'receber',
        headerName: 'A Receber',
        type: 'string',
        flex: 1,
        minWidth: 110,
        editable: false,
        valueFormatter: (params) => {
            return formataDinheiro(params.value);
        },
    },    
    {
        field: 'pagar',
        headerName: 'A Pagar',
        type: 'string',
        flex: 1,
        minWidth: 110,
        editable: false,
        valueFormatter: (params) => {
            return formataDinheiro(params.value);
        },
    },
    {
      field: 'estoque',
      headerName: 'Estoque',
      type: 'string',
      flex: 1,
      minWidth: 110,
      editable: false,
      valueFormatter: (params) => {
          return formataDinheiro(params.value);
      },
    },
    {
      field: 'saldo',
      headerName: 'Saldo',
      type: 'string',
      flex: 1,
      minWidth: 120,
      editable: false,
      valueFormatter: (params) => {
          return formataDinheiro(params.value);
      },
    },
    
  ];


  function nenhumFluxoEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconNoFluxo/>
                <p>Nenhum fluxo</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar o fluxo de caixa!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }


  const getDataExportFluxo = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let fluxo = await api.post('listagemPessoasSelecionadas', {
      'fluxosIds': fluxosSelecionados
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setlistagemFluxoExport(fluxo.data);
    setloaderPage(false);

    return fluxo.data;
  }
  
  const handleExportPDFfluxo = async()=>{
    
    // let listagem = await getDataExportFluxo();

    let dados = {
      fluxos,
      
      resumoAnteriorTotalCaixas,
      resumoAnteriorTotalBancos,
      resumoAnteriorTotalAReceber,
      resumoAnteriorTotalApagar,
      resumoAnteriorTotalEstoque,
      resumoAnteriorTotalSaldo,

      resumoFinalTotalCaixas,
      resumoFinalTotalBancos,
      resumoFinalTotalAReceber,
      resumoFinalTotalApagar,
      resumoFinalTotalEstoque,
      resumoFinalTotalSaldo
    }

    pdf(<DocumentPDFfluxo startDate={moment(filtroData[0]?.startDate).format('YYYY-MM-DD HH:mm:ss')} endDate={moment(filtroData[0]?.endDate).format('YYYY-MM-DD HH:mm:ss')} dados={dados} />).toBlob().then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'Fluxo-de-caixa.pdf';
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
    });

  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Fluxo de caixa</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>

          <SubMenusFinancas activePage="fluxoCaixa" />
          
          <div className='container'>

            <div className='box-actions-fluxos'>

                <Dialog open={showModalfiltroData} maxWidth='lg' fullWidth={false} scroll='body' onClose={()=>{ setshowModalfiltroData(false) }}>
                  <DialogTitle sx={{ m: 0, p: 2 }}>
                      Filtrar fluxo por data
                      <IconButton
                        aria-label="close"
                        onClick={()=>{ setshowModalfiltroData(false) }}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText></DialogContentText>

                      <DateRangePicker
                        onChange={item => setfiltroData([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={filtroData}
                        direction="horizontal"
                        locale={locales['pt']}
                        dateDisplayFormat="dd, MMMM yyyy"
                        inputRanges={[
                          {
                            label: 'dia até hoje',
                            range(value) {
                              return {
                                startDate: addDays(startOfDay(new Date()), (Math.max(Number(value), 1) - 1) * -1),
                                endDate: endOfDay(new Date()),
                              };
                            },
                            getCurrentValue(range) {
                              if (!isSameDay(range.endDate, endOfDay(new Date()) )) return '-';
                              if (!range.startDate) return '∞';
                              return differenceInCalendarDays(endOfDay(new Date()), range.startDate) + 1;
                            },
                          },
                          {
                            label: 'dias a partir de hoje',
                            range(value) {
                              const today = new Date();
                              return {
                                startDate: today,
                                endDate: addDays(today, Math.max(Number(value), 1) - 1),
                              };
                            },
                            getCurrentValue(range) {
                              if (!isSameDay(range.startDate, startOfDay(new Date()))) return '-';
                              if (!range.endDate) return '∞';
                              return differenceInCalendarDays(range.endDate, startOfDay(new Date())) + 1;
                            },
                          },
                        ]}
                        staticRanges={[
                          {
                            label: "Hoje",
                            range: () => ({
                              startDate: startOfDay(new Date()),
                              endDate: endOfDay(new Date())
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Ontem",
                            range: () => ({
                              startDate: startOfDay(addDays(new Date(), -1)),
                              endDate: endOfDay(addDays(new Date(), -1))
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Esta Semana",
                            range: () => ({
                              startDate: startOfWeek(new Date()),
                              endDate: endOfWeek(new Date())
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Semana passada",
                            range: () => ({
                              startDate: startOfWeek(addDays(new Date(), -7)),
                              endDate: endOfWeek(addDays(new Date(), -7))
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Este Mês",
                            range: () => ({
                              startDate: startOfMonth(new Date()),
                              endDate: endOfDay(new Date())
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Mês passado",
                            range: () => ({
                              startDate: startOfMonth(addMonths(new Date(), -1)),
                              endDate: endOfMonth(addMonths(new Date(), -1))
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Ano passado",
                            range: () => ({
                              startDate: startOfYear(addYears(new Date(), -1)),
                              endDate: endOfYear(addYears(new Date(), -1))
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          },
                          {
                            label: "Este Ano",
                            range: () => ({
                              startDate: startOfYear(new Date()),
                              endDate: endOfDay(new Date())
                            }),
                            isSelected(range) {
                              const definedRange = this.range();
                              return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                              );
                            }
                          }
                        ]}
                      />

                    <DialogActions>
                      <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '0px'}}>

                        <Grid item xs={4} md={3}>
                          <ButtonOutlineSecondary type="button" onClick={()=>{ setshowModalfiltroData(false) }} icon="" txt="Cancelar" />
                        </Grid>
                        
                        <Grid item xs={4} md={3}>
                          <ButtonDefault type="submit" icon=""  txt="Filtrar" onClick={()=>{ handleFluxoFiltroData() }} />
                        </Grid>

                      </Grid>
                    </DialogActions>

                  </DialogContent>
                </Dialog>
                
                <div style={{ 'marginLeft': '5px' }}>
                  <ButtonSquadSecondary  icon={<IconPDFbtn/>} onClick={()=>{ handleExportPDFfluxo() }}/>
                </div>                 

                {
                fluxosSelecionados.length > 0 &&
                <>  
                  
                  <div style={{ 'marginLeft': '5px' }}>
                      <ButtonSquadSecondary icon={<IconCSVBtn/>} onClick={()=>{ /*handleExportCSVPessoas()*/ }}/>
                  </div>

                </>
                }

                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    startText="Check-in"
                    endText="Check-out"
                    value={filtroData}
                    onChange={(newValue) => {
                      setfiltroData(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider> */}
                
                <div style={{ 'marginLeft': '5px' }}>
                  <ButtonSquadSecondary icon={<IconCalendar/>} onClick={()=>{ setshowModalfiltroData(true); }}/>
                </div>
                
                {
                  filtroData[0]?.startDate &&

                  <div className='display-data-fluxo-filtro' onClick={()=>{ setshowModalfiltroData(true); }}>
                    <p><b>{moment(filtroData[0]?.startDate).format('DD/MM/YYYY')}</b> até <b>{moment(filtroData[0]?.endDate).format('DD/MM/YYYY')}</b></p>
                  </div>

                }

                <div style={{ 'marginLeft': '5px', 'width': '100%' }}>
                    {/* <InputSearchActions onChange={handleSearchFluxos}  placeholder="Procure um lançamento" /> */}
                </div>
            </div>
              
            <div className='box-resumo-saldo'>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 300 }} style={{border:'none'}}>SALDO ANTERIOR</TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Caixas</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalCaixas?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Bancos</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalBancos?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>A Receber</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalAReceber?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>A Pagar</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalApagar?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Estoque</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalEstoque?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Saldo</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoAnteriorTotalSaldo?.total)}</p></TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>


            <div className='container-data-grid'>
                <DataGrid
                  sx={{ minHeight: '400px' }}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  autoHeight
                  rows={fluxos}
                  columns={columnsDataGridFluxos}
                  pageSize={pageSizeDataGridFluxos}
                  onPageSizeChange={(newPageSize) => setpageSizeDataGridFluxos(newPageSize)}
                  rowsPerPageOptions={[10, 50, 100]} 
                  checkboxSelection
                  disableSelectionOnClick
                  editMode='row'
                  loading={loadingDataGridFluxo}
                  disableColumnMenu={true}
                  onSelectionModelChange={(props)=>{
                      setfluxosSelecionados(props);
                  }}
                  // onRowClick
                  onCellClick={(props)=>{
                  if(props.field !== "__check__"){
                      // handleDetalhesPessoa(props)
                  }
                  }}
                  scrollbarSize={5}
                  components={{
                      NoRowsOverlay: nenhumFluxoEncontrado,
                  }}
                  
                />
            </div>
            
            <div className='box-resumo-saldo'>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 300 }} style={{border:'none'}}>SALDO FINAL</TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Caixas</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalCaixas?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Bancos</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalBancos?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>A Receber</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalAReceber?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>A Pagar</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalApagar?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Estoque</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalEstoque?.total)}</p></TableCell>
                      <TableCell sx={{ minWidth: 150 }} style={{border:'none'}}><b style={{fontSize: '12px'}}>Saldo</b> <p style={{ lineHeight: '0', marginTop: '5px', fontSize: '16px', fontWeight: 'normal' }}>{formataDinheiro(resumoFinalTotalSaldo?.total)}</p></TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>


          </div>
        </div>
        <Footer/>
      </div>
    </>
    
  );
}